import {
  CLEAR_CART,
  ADD_TO_CART,
  UPDATE_CART,
  REMOVE_FROM_CART,
} from "../actions/types";

const cart = (state = [], action) => {
  switch (action.type) {
    case ADD_TO_CART:
      let productExists = state.filter(
        (cartItem) => cartItem.product_id == action.payload.product_id
      );
      let restOfCart = state.filter(
        (cartItem) => cartItem.product_id != action.payload.product_id
      );
      if (productExists.length > 0) {
        productExists[0].quantity += action.payload.quantity;
        return [...restOfCart, productExists[0]];
      } else {
        return [...state, action.payload];
      }
    case REMOVE_FROM_CART:
      return state.filter((cartItem) => cartItem.product_id != action.payload);
    case UPDATE_CART:
      let newState = state.map((cartItem) => {
        if (cartItem.product_id == action.payload.product_id) {
          cartItem.quantity = action.payload.quantity;
        }
        return cartItem;
      });
      return newState;
    case CLEAR_CART:
      return [];
    default:
      return state;
  }
};

export default cart;
