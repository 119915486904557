import { GET_LOCATION } from "../actions/types";

let initialState = {
  ip: "",
  country_name: "",
  country_code2: "",
  isp: "",
};

const location = (state = initialState, action) => {
  switch (action.type) {
    case GET_LOCATION:
      return action.payload;
    default:
      return state;
  }
};

export default location;
