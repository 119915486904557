import React from "react";
import MyInput from "../MyInput";
import MyInputFormGroup from "../MyInputFormGroup";

const AllModals = ({
  name,
  onNameChange,
  email,
  onEmailChange,
  phone,
  onPhoneChange,
  company,
  onCompanyChange,
  message,
  onMessageChange,
  onSubmit,
}) => {
  return (
    <>
      {/* Book A Demo Modal Start Here */}
      <div
        className="modal fade"
        id="bookDemo"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="bookDemoTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-body">
              {/* <div className="heading-wrapper">
                                <h2>Book A Demo</h2>
                            </div> */}
              <form action="">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        placeholder="Full Name"
                        className="form-control"
                        value={name}
                        onChange={onNameChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="email"
                        placeholder="Email Address"
                        className="form-control"
                        value={email}
                        onChange={onEmailChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="tel"
                        placeholder="Phone"
                        className="form-control"
                        value={phone}
                        onChange={onPhoneChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        placeholder="Facility Name"
                        className="form-control"
                        value={company}
                        onChange={onCompanyChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <textarea
                        placeholder="Message"
                        className="form-control"
                        maxLength="250"
                        value={message}
                        onChange={onMessageChange}
                      ></textarea>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="button-group">
                      <button
                        className="primary-btn"
                        type="button"
                        data-dismiss="modal"
                        onClick={onSubmit}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* Book A Demo Modal End Here */}

      {/* Add User Modal */}
      {/* <div class="modal fade" id="AddUser" tabindex="-1" role="dialog" aria-labelledby="AddUserTitle" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div className="heading text-center pt-4 pb-2">
              <h3 className='text-dark'>Add User</h3>
            </div>
            <div class="modal-body">
              <form action="">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <MyInput
                        placeholder="Full Name"
                        inputId="fname"
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <MyInput
                        placeholder="Last Name"
                        inputId="lname"
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <MyInputFormGroup
                        placeholder="Email"
                        inputId="email"
                        type="email"
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <MyInputFormGroup
                        inputClassNames={["form-control"]}
                        placeholder="Select"
                        type="select"
                        options={SubscriptionOptions}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="button-group">
                      <button className="primary-btn w-100">Submit</button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div> */}
      {/* Add User Modal */}
    </>
  );
};

export default AllModals;
