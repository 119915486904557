import React, { useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import "../../assets/css/bc-system.css";
import {
  BcBannerImg,
  BcIcon1,
  BcIcon2,
  IpacIcon,
  Step1Img,
  Step2Img,
  Step3Img,
  Step4Img,
} from "../../constant/Index";
import config from "../../config";
import { CONTACT_ENDPOINT } from "../../endpoints";
import OverlaySpinner from "../../components/OverlaySpinner";
import AllModals from "../../components/AllModals/AllModals";

const BcSystem = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [company, setCompany] = useState("");
  const [location, setLocation] = useState("");
  const [message, setMessage] = useState("");

  const [type, setType] = useState(null);

  const [spinner, setSpinner] = useState(false);

  const onSubmit = async () => {
    try {
      setSpinner(true);
      await config.postApi(
        CONTACT_ENDPOINT,
        config.defaultHeaders,
        JSON.stringify({
          name,
          email,
          phone,
          company,
          location,
          message,
          type,
        })
      );
      setSpinner(false);
    } catch (e) {
      setSpinner(false);
      console.log(e);
    }
  };

  return (
    <>
      {/* Header Start Here */}
      <Header />
      {/* Header Start Here */}

      {/* All Modals */}
      <AllModals
        name={name}
        onNameChange={(e) => setName(e.target.value)}
        email={email}
        onEmailChange={(e) => setEmail(e.target.value)}
        phone={phone}
        onPhoneChange={(e) => setPhone(e.target.value)}
        company={company}
        onCompanyChange={(e) => setCompany(e.target.value)}
        message={message}
        onMessageChange={(e) => setMessage(e.target.value)}
        onSubmit={onSubmit}
      />
      {/* All Modals */}

      {/* InnerBanner Sec Start Here */}
      <section className="bc-inner-banner">
        <div className="img-box wow animate__animated animate__zoomIn">
          <figure>
            <img src={BcBannerImg} alt="inner-banner" className="img-fluid" />
          </figure>
        </div>
      </section>
      {/* InnerBanner Sec End Here */}

      {/* Card Boxes Start Here */}
      <section className="card-boxes">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="card-box wow animate__animated animate__flipInX">
                <div className="icon">
                  <figure>
                    <img src={BcIcon1} alt="icon" className="img-fluid" />
                  </figure>
                </div>
                <div className="txt">
                  <h3>
                    Is infection surveillance an added burden in your facility?
                  </h3>
                  <p>
                    Is Antimicrobial Stewardship an active part of your IPC
                    plan? Infection prevention is now the priority in aged care.
                    Reducing HAIs and prioritising antimicrobial resistance is
                    of immediate concern, but this may seem impossible with
                    reduced resources and time.
                  </p>
                </div>
                {/* Buttons Group Sec Start Here */}
                <div className="enquiry-btn wow animate__animated animate__slideInUp p-0">
                  <div className="button-group">
                    <button
                      className="primary-btn"
                      type="button"
                      data-toggle="modal"
                      data-target="#bookDemo"
                      onClick={() => setType("Book a Demo of BC System")}
                    >
                      Book a Demo
                    </button>
                  </div>
                </div>
                {/* Buttons Group Sec End Here */}
              </div>
            </div>
            <div className="col-lg-6">
              <div className="card-box wow animate__animated animate__flipInX animate__delay-1s">
                <div className="icon">
                  <figure>
                    <img src={BcIcon2} alt="icon" className="img-fluid" />
                  </figure>
                </div>
                <div className="txt">
                  <h3>Haist Infection Surveillance will relieve the burden.</h3>
                  <p>
                    Haist makes infection surveillance simple, accurate and
                    consistent. Our mobile systematic infection surveillance
                    provides clinical pathways and timely reporting to enable
                    prompt action and appropriate treatment.
                  </p>
                </div>
                {/* Buttons Group Sec Start Here */}
                <div className="enquiry-btn wow animate__animated animate__slideInUp p-0">
                  <div className="button-group">
                    <button
                      className="primary-btn"
                      type="button"
                      data-toggle="modal"
                      data-target="#bookDemo"
                      onClick={() => setType("Inquiry about BC System")}
                    >
                      Make an Enquiry
                    </button>
                  </div>
                </div>
                {/* Buttons Group Sec End Here */}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Card Boxes End Here */}

      {/* Step Sec Start Here */}
      <section className="step_sec step-one">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-lg-6">
              <div className="content-box wow animate__animated animate__fadeInLeft">
                <div className="number wow animate__animated animate__bounceIn animate__delay-2s">
                  <h2>01</h2>
                </div>
                <div className="txt-box">
                  <h2>Clinical pathways for infection identification.</h2>
                  <p>
                    Accurate assessments are crucial to the resident receiving
                    the appropriate treatment. Effectively communicated
                    assessments leads to appropriate treatment supporting your
                    AMS program.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="mobile-image wow animate__animated animate__fadeInRight animate__delay-1s">
                <figure>
                  <img src={Step1Img} alt="step-one" className="img-fluid" />
                </figure>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Step Sec Start Here */}

      {/* Step Sec Start Here */}
      <section className="step_sec step-two">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-lg-4">
              <div className="mobile-image wow animate__animated animate__fadeInLeft animate__delay-1s">
                <figure>
                  <img src={Step2Img} alt="step-one" className="img-fluid" />
                </figure>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="content-box wow animate__animated animate__fadeInRight">
                <div className="number wow animate__animated animate__bounceIn animate__delay-2s">
                  <h2>02</h2>
                </div>
                <div className="txt-box">
                  <h2>Resident outcomes.</h2>
                  <p>
                    Timely reporting improves resident outcomes and reduces the
                    need for hospitalisation.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Step Sec Start Here */}

      {/* Step Sec Start Here */}
      <section className="step_sec step-three">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-lg-6">
              <div className="content-box wow animate__animated animate__fadeInLeft">
                <div className="number wow animate__animated animate__bounceIn animate__delay-2s">
                  <h2>03</h2>
                </div>
                <div className="txt-box">
                  <h2>Eliminates time consuming processes.</h2>
                  <p>
                    Haist revolutionises infection surveillance, eliminating
                    time consuming form filling and inputting data. Our app is
                    seamless, providing a systematic and secure approach with
                    automated data collection.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="mobile-image wow animate__animated animate__fadeInRight animate__delay-1s">
                <figure>
                  <img src={Step3Img} alt="step-one" className="img-fluid" />
                </figure>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Step Sec Start Here */}

      {/* Step Sec Start Here */}
      <section className="step_sec step-two">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-lg-4">
              <div className="mobile-image wow animate__animated animate__fadeInLeft animate__delay-1s">
                <figure>
                  <img src={Step4Img} alt="step-one" className="img-fluid" />
                </figure>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="content-box wow animate__animated animate__fadeInRight">
                <div className="number wow animate__animated animate__bounceIn animate__delay-2s">
                  <h2>04</h2>
                </div>
                <div className="txt-box">
                  <h2>More than just meeting the standards.</h2>
                  <p>
                    The Haist records all the required data you need to report
                    on infection surveillance. Review infections daily implement
                    change and reduce outbreaks and their impact on residents
                    and staff.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="enquiry-btn wow animate__animated animate__fadeInUp">
          <div className="button-group">
            <button
              className="primary-btn"
              type="button"
              data-toggle="modal"
              data-target="#bookDemo"
              onClick={() => setType("Book a Demo of BC System")}
            >
              Book a Demo
            </button>
            <button
              className="primary-btn"
              type="button"
              data-toggle="modal"
              data-target="#bookDemo"
              onClick={() => setType("Inquiry about BC System")}
            >
              Make an Enquiry
            </button>
          </div>
        </div>
      </section>
      {/* Step Sec Start Here */}

      {/* Enquiry Today Sec Start Here */}
      <section className="enquiry_today_sec">
        <div className="container">
          <div className="row">
            <div className="row align-items-center">
              <div className="col-lg-7">
                <div className="content-wrapper wow animate__animated animate__backInLeft">
                  <h2>Make a no-obligation enquiry today.</h2>
                  <p>
                    Our specialised client support staff are aged care and home
                    care professionals. An initial consultation is fast, helping
                    you understand how Haist can significantly improve your
                    infection surveillance.
                  </p>
                </div>
              </div>
              <div className="col-lg-5">
                <div className="img-box wow animate__animated animate__backInRight animate__delay-1s">
                  <figure>
                    <img src={IpacIcon} alt="ipac-icon" className="img-fluid" />
                  </figure>
                </div>
              </div>
            </div>
          </div>
          <div className="enquiry-btn">
            <div className="button-group">
              <button
                className="primary-btn"
                type="button"
                data-toggle="modal"
                data-target="#bookDemo"
                onClick={() => setType("Book a Demo of BC System")}
              >
                Book a Demo
              </button>
              <button
                className="primary-btn wow animate__animated animate__bounceIn"
                type="button"
                data-toggle="modal"
                data-target="#bookDemo"
                onClick={() => setType("Inquiry about BC System")}
              >
                Make an Enquiry
              </button>
            </div>
          </div>
        </div>
      </section>
      {/* Enquiry Today Sec End Here */}

      {/* Footer Start Here */}
      <Footer />
      {/* Footer End Here */}

      {spinner && <OverlaySpinner />}
    </>
  );
};

export default BcSystem;
