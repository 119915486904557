import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import addresses from "./addresses";
import auth from "./auth";
import categories from "./categories";
import paymentMethods from "./paymentMethods";
import cart from "./cart";
import packages from "./packages";
import orders from "./orders";
import location from "./location";
import policies from "./policies";

const rootReducer = combineReducers({
  auth: auth,
  paymentMethods: paymentMethods,
  categories: categories,
  addresses: addresses,
  cart: cart,
  packages,
  orders,
  location,
  policies,
});

const persistedReducer = persistReducer(
  {
    key: "root",
    storage: storage,
  },
  rootReducer
);

export default persistedReducer;
