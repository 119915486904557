import React, { useState } from "react";
import { connect } from "react-redux";
import {
  Form,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from "reactstrap";
import FormException from "../exceptions/FormException";
import { LoginAction, RegisterAction } from "../redux/actions/auth";
import { GetPaymentMethodsAction } from "../redux/actions/paymentMethods";
import MyInputFormGroup from "./MyInputFormGroup";

const LoginModal = ({
  isOpen,
  toggle,
  login,
  register,
  getPaymentMethods,
  cb = null,
}) => {
  const [showRegister, setShowRegister] = useState(false);

  const [fName, setFName] = useState("");
  const [lName, setLName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [facility, setFacility] = useState("");

  const [loggingIn, setLoggingIn] = useState(false);

  const [errors, setErrors] = useState({});

  const loginFn = async () => {
    try {
      setLoggingIn(true);
      await login(email, password);
      await getPaymentMethods();
      setErrors({});
      setLoggingIn(false);
      setEmail("");
      setPassword("");
      if (cb != null) {
        console.log(cb);
        cb();
      } else {
        toggle();
      }
    } catch (exception) {
      setLoggingIn(false);
      if (exception instanceof FormException) {
        setErrors(exception.errors);
      }
      console.log(exception);
    }
  };

  const registerFn = async () => {
    try {
      setLoggingIn(true);
      await register(
        fName,
        lName,
        email,
        password,
        confirmPassword,
        facility,
        true
      );
      await getPaymentMethods();
      setErrors({});
      setLoggingIn(false);
      setFName("");
      setLName("");
      setEmail("");
      setPassword("");
      setConfirmPassword("");
      setFacility("");
      if (cb != null) {
        console.log(cb);
        cb();
      } else {
        toggle();
      }
    } catch (exception) {
      setLoggingIn(false);
      if (exception instanceof FormException) {
        setErrors(exception.errors);
      }
      console.log(exception);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (showRegister) {
      await registerFn();
    } else {
      await loginFn();
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <Form onSubmit={handleSubmit}>
        <ModalHeader toggle={toggle}>
          {showRegister ? "Register" : "Login"}
        </ModalHeader>
        <ModalBody>
          {showRegister ? (
            <>
              <MyInputFormGroup
                showLabel
                label="First Name"
                value={fName}
                onChange={(e) => setFName(e.target.value)}
                placeholder="Enter First Name"
                errors={errors.first_name}
                required
              />
              <MyInputFormGroup
                showLabel
                label="Last Name"
                value={lName}
                onChange={(e) => setLName(e.target.value)}
                placeholder="Enter Last Name"
                errors={errors.last_name}
                required
              />
              <MyInputFormGroup
                showLabel
                label="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                placeholder="Enter Email"
                errors={errors.email}
                required
              />
              <MyInputFormGroup
                showLabel
                label="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                type="password"
                placeholder="Enter Password"
                errors={errors.password}
                required
              />
              <MyInputFormGroup
                showLabel
                label="Confirm Password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                type="password"
                placeholder="Enter Confirm Password"
                errors={errors.password_confirmation}
                required
              />
              <MyInputFormGroup
                showLabel
                label="Facility"
                value={facility}
                onChange={(e) => setFacility(e.target.value)}
                placeholder="Enter Facility Name"
                errors={errors.facility}
                required
              />
            </>
          ) : (
            <>
              <MyInputFormGroup
                showLabel
                label="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                placeholder="Enter Email"
                errors={errors.email}
                required
              />
              <MyInputFormGroup
                showLabel
                label="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                type="password"
                placeholder="Enter Password"
                errors={errors.password}
                required
              />
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <button type="button" onClick={toggle} className="btn">
            Cancel
          </button>
          {!showRegister ? (
            <button
              type="submit"
              className="primary-btn"
              disabled={loggingIn}
              key="do_login"
            >
              {loggingIn ? <Spinner size={"sm"} /> : "Login"}
            </button>
          ) : (
            <button
              key={"show_login_form"}
              type="button"
              className="primary-btn"
              disabled={loggingIn}
              onClick={() => setShowRegister(false)}
            >
              {loggingIn ? <Spinner size={"sm"} /> : "Login"}
            </button>
          )}
          {showRegister ? (
            <button
              type="submit"
              className="primary-btn"
              disabled={loggingIn}
              key="do_register"
            >
              {loggingIn ? <Spinner size={"sm"} /> : "Register"}
            </button>
          ) : (
            <button
              key="show_register_form"
              type="button"
              onClick={() => setShowRegister(true)}
              className="primary-btn"
              disabled={loggingIn}
            >
              {loggingIn ? <Spinner size={"sm"} /> : "Register"}
            </button>
          )}
        </ModalFooter>
      </Form>
    </Modal>
  );
};

const mapDispatchToProps = (dispatch) => ({
  login: async (email, password) =>
    dispatch(await LoginAction(email, password)),
  register: async (
    first_name,
    last_name,
    email,
    password,
    confirm_password,
    facility,
    login = false
  ) =>
    dispatch(
      await RegisterAction(
        first_name,
        last_name,
        email,
        password,
        confirm_password,
        null,
        facility,
        login
      )
    ),
  getPaymentMethods: async () => dispatch(await GetPaymentMethodsAction()),
});

export default connect(null, mapDispatchToProps)(LoginModal);
