import React from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

const ViewCourseModal = ({ isOpen, toggle, enrollments }) => {
  return (
    <>
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        className="view-course"
        centered={true}
      >
        <ModalHeader toggle={toggle}>View Assigned Courses</ModalHeader>
        <ModalBody>
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th className="sno">S.NO</th>
                  <th className="assigned-course">Assigned Course</th>
                  {/* <th className="delete">Delete</th> */}
                </tr>
              </thead>
              <tbody>
                {enrollments.map((enrollment, index) => (
                  <tr key={`enrollment_${enrollment.id}`}>
                    <td className="sno">{index + 1}</td>
                    <td className="assigned-course">
                      {enrollment.course?.title}
                    </td>
                    {/* <td className="delete">
                      <button
                        className="delete-btn"
                        type="button"
                        onClick={() => deleteCourse(enrollment.id)}
                      >
                        <i class="fa fa-trash-o" aria-hidden="true"></i>
                      </button>
                    </td> */}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </ModalBody>
        <ModalFooter>
          <button type="button" className="primary-btn" onClick={toggle}>
            Close
          </button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default ViewCourseModal;
