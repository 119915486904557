import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { constants, kwdiImg, Logo } from "../../constant/Index";

const Footer = ({ reduxLocation }) => {
  return (
    <>
      {/* Footer Sec Start Here */}
      <footer>
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="footer-content">
                <div className="logo-box">
                  <Link to="#">
                    <figure>
                      <img src={Logo} alt="footer-logo" className="img-fluid" />
                    </figure>
                  </Link>
                </div>
                <ul className="info">
                  <li>
                    <a
                      href={
                        "mailto:" +
                        (constants.contact_info[reduxLocation.country_code2]
                          ? constants.contact_info[reduxLocation.country_code2]
                              .email
                          : constants.contact_info[
                              constants.contact_info_fallback
                            ].email)
                      }
                    >
                      {constants.contact_info[reduxLocation.country_code2]
                        ? constants.contact_info[reduxLocation.country_code2]
                            .email
                        : constants.contact_info[
                            constants.contact_info_fallback
                          ].email}
                    </a>
                  </li>
                  <li>
                    <a
                      href={
                        "tel:" +
                        (constants.contact_info[reduxLocation.country_code2]
                          ? constants.contact_info[reduxLocation.country_code2]
                              .phone
                          : constants.contact_info[
                              constants.contact_info_fallback
                            ].phone)
                      }
                    >
                      {constants.contact_info[reduxLocation.country_code2]
                        ? constants.contact_info[reduxLocation.country_code2]
                            .phone
                        : constants.contact_info[
                            constants.contact_info_fallback
                          ].phone}
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      {constants.contact_info[reduxLocation.country_code2]
                        ? constants.contact_info[reduxLocation.country_code2]
                            .address
                        : constants.contact_info[
                            constants.contact_info_fallback
                          ].address}
                    </a>
                  </li>
                  <li className="social-icons">
                    <a
                      href="https://www.facebook.com/BugControlAustraliaAndNewZealand/"
                      target={"_blank"}
                    >
                      <i
                        className="fa fa-facebook-official"
                        aria-hidden="true"
                      ></i>
                    </a>
                    <span className="seprator"></span>
                    <a
                      href="https://www.linkedin.com/company/bug-control-australia-&-new-zealand-infection-control-consultants/"
                      target="_blank"
                    >
                      <i
                        className="fa fa-linkedin-square"
                        aria-hidden="true"
                      ></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row">
                <div className="col-md-6">
                  <div className="footer-content">
                    <ul className="menu-links">
	  {/*
                      <li>
                        <Link target="_blank" to={constants.links.kb}>
                          Knowledge Base Login
                        </Link>
                      </li>*/}
                      <li>
                        <Link to="/packages">Subscription</Link>
                      </li>
                      <li>
                        <Link to="/shop">Shop</Link>
                      </li>
                      <li>
                        <Link to="/bc-systems">BC Systems</Link>
                      </li>
                      <li>
                        <Link to="/contact-us">Contact</Link>
                      </li>
                      {/* <li>
                        <Link to="/contact-us#faqs_section">FAQs</Link>
                      </li> */}
                    </ul>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="footer-content">
                    <ul className="menu-links">
                      <li>
                        <Link target="_blank" to="https://infectioncontrol.talentlms.com/index">
                          LMS Login
                        </Link>
                      </li>
                      <li>
                        <Link to="/lead-course">Education</Link>
                      </li>
                      <li>
                        <Link to="/about-us">About</Link>
                      </li>
                      <li>
                        <Link to="/news">News</Link>
                      </li>
                      <li>
                        <Link to="/terms-and-condition">Legal</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="note">
                <p>
                  {constants.contact_info[reduxLocation.country_code2]
                    ? constants.contact_info[reduxLocation.country_code2]
                        .footer_content
                    : constants.contact_info[constants.contact_info_fallback]
                        .footer_content}
                </p>
              </div>
            </div>
          </div>
          <div className="copyright">
            <p className="rights">© Copyright Bug Control, 2023</p>
            <div className="img-box">
              <a href="#">
                <figure>
                  <img src={kwdiImg} alt="kwdi" className="img-fluid" />
                </figure>
              </a>
            </div>
          </div>
        </div>
      </footer>
      {/* Footer Sec End Here */}
    </>
  );
};

const mapStateToProps = (state) => ({
  reduxLocation: state.location,
});

export default connect(mapStateToProps)(Footer);
