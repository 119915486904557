import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../../assets/css/auth.css";
import MyInput from "../../components/MyInput";
import { Logo } from "../../constant/Index";
import MySpinner from "../../components/MySpinner";
import { connect } from "react-redux";
import { LoginAction } from "../../redux/actions/auth";
import FormException from "../../exceptions/FormException";

const Login = ({ login }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [errors, setErrors] = useState({});

  const [spinner, setSpinner] = useState(false);

  const navigate = useNavigate();

  const submit = async () => {
    try {
      setSpinner(true);
      const auth = await login(email, password);
      setSpinner(false);
      setErrors({});
      setEmail("");
      setPassword("");
      if (auth.user.facility?.facility_owner_id == auth.user.id) {
        navigate("/user-dashboard");
      } else {
        navigate("/profile");
      }
    } catch (e) {
      setSpinner(false);
      if (e instanceof FormException) {
        setErrors(e.errors);
      }
    }
  };

  return (
    <>
      {/* Login Sec Start Here */}
      <section className="auth login">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-7 col-md-6 px-0">
              <div className="row mx-0 h-100">
                <div className="col-xl-6 col-lg-10 col-md-10 col-sm-8 mx-auto">
                  <div className="auth_start">
                    <div className="logo">
                      <Link to="/">
                        <img src={Logo} alt="logo" className="img-fluid" />
                      </Link>
                    </div>
                    <div className="wraper">
                      <div className="top_text">
                        <h2>Login</h2>
                        <h4>
                          Welcome Back to{" "}
                          <span className="colored-txt">
                            Infection Prevention
                          </span>
                        </h4>
                        <p>
                          Not a Member ? <Link to="/register">Register</Link>
                        </p>
                      </div>
                      <div className="form_start mt-5">
                        {/* <form action=""> */}
                        <MyInput
                          type="email"
                          inputId="email"
                          placeholder="Email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          errors={errors.email}
                        />
                        <MyInput
                          type="password"
                          inputId="password"
                          placeholder="Password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          errors={errors.password}
                        />
                        <div className="save_password">
                          <div className="form-check">
                            <input
                              type="checkbox"
                              width="auto"
                              id="checkBox"
                              className="form-checkbox"
                            />
                            <label htmlFor="checkBox">Remember Me</label>
                          </div>
                          <Link to="/forgot-password">Forgot Password</Link>
                        </div>
                        <div className="form_row mt-3">
                          <button
                            className="primary-btn center d-block w-100"
                            type="button"
                            onClick={submit}
                            disabled={spinner}
                          >
                            {spinner ? <MySpinner /> : "Login"}
                          </button>
                        </div>
                        {/* </form> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-5 col-md-6 px-0">
              <div className="side-image">
                <div className="main_img">
                  <Link to="/" className="d-block">
                    <img src={Logo} className="img-fluid" alt="" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Login Sec End Here */}
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  login: async (email, password) =>
    dispatch(await LoginAction(email, password)),
});

export default connect(null, mapDispatchToProps)(Login);
