import React, { useEffect, useState } from "react";
import InnerBanner from "../../components/InnerBanner/InnerBanner";
import { EducationalResourceImg } from "../../constant/Index";
import "../../assets/css/subscription.css";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import config from "../../config";
import { connect } from "react-redux";
import { PACKAGES_ENDPOINT, SUBSCRIPTIONS_ENDPOINT } from "../../endpoints";
import { UpdateProfileAction } from "../../redux/actions/auth";
import AddPaymentMethodModal from "../../components/AddPaymentMethodModal";
import { Spinner } from "reactstrap";
import LoginModal from "../../components/LoginModal";

const Subscription = ({ auth, paymentMethods, updateProfile }) => {
  const [packages, setPackages] = useState([]);
  const [buyingPackageId, setBuyingPackageId] = useState(null);
  const [addPaymentMethodModal, setAddPaymentMethodModal] = useState(false);
  const [loginModal, setLoginModal] = useState(false);

  const [spinner, setSpinner] = useState(false);

  const getPackages = async () => {
    const headers = config.bearerHeaders(auth.token);
    const res = await config.getApi(
      PACKAGES_ENDPOINT + "?package_type=module",
      headers
    );
    if (res) {
      console.log(res);
      setPackages(res);
    }
  };

  const onBuy = async (packageId) => {
    setBuyingPackageId(packageId);
    if (auth.token) {
      if (paymentMethods.length > 0) {
        try {
          setSpinner(true);
          const headers = config.bearerHeaders(auth.token);
          const res = await config.postApi(
            SUBSCRIPTIONS_ENDPOINT,
            headers,
            JSON.stringify({ package_id: packageId })
          );
          if (res) {
            updateProfile(res);
          }
          setSpinner(false);
        } catch (e) {
          setSpinner(false);
          console.log(e);
        }
      } else {
        setAddPaymentMethodModal(true);
      }
    } else {
      setLoginModal(true);
      // navigate("/login");
    }
  };

  useEffect(() => {
    getPackages();
  }, []);

  return (
    <>
      {/* Header Start Here */}
      <Header />
      {/* Header End Here */}

      {/* <!-- Banner Sec Start Here --> */}
      <InnerBanner
        PageName="Subscription"
        BannerImage={EducationalResourceImg}
      />
      {/* <!-- Banner Sec Start Here --> */}

      {/* <!-- Subscription Sec Start Here --> */}
      <section className="subscription">
        <div className="container">
          <div className="heading-wrapper">
            <h1>Choose Your Plan</h1>
          </div>
          <div className="row justify-content-center">
            {packages.map((singlePackage) => (
              <div
                className="col-md-5"
                key={`module_package_${singlePackage.id}`}
              >
                <div className="package-box first-package">
                  <div className="tag">
                    <p>
                      ${singlePackage.price} a Month
                      <br />
                      (Unlimited Plan)
                    </p>
                  </div>
                  <div className="header-bar">
                    <h4>{singlePackage.name}</h4>
                    <h2>${singlePackage.price}</h2>
                    <h5>Per Month</h5>
                  </div>
                  <p
                    className="description"
                    dangerouslySetInnerHTML={{
                      __html: singlePackage.detailed_description,
                    }}
                  ></p>
                  <ul className="list">
                    {singlePackage.modules.map((singleModule) => (
                      <li key={`single_module_${singleModule.id}`}>
                        <i className="fa fa-check"></i> {singleModule.name}
                      </li>
                    ))}
                  </ul>
                  <div className="button-group">
                    {/* <a href="#">Watch a Demo</a> */}
                    <button
                      type="button"
                      onClick={() => onBuy(singlePackage.id)}
                      disabled={spinner}
                    >
                      {spinner ? <Spinner /> : "Buy Now"}
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      {/* <!-- Subscription Sec End Here --> */}

      {/* <!-- Combo Package Start Here --> */}
      {/* <section className="combo_sec">
        <div className="container">
          <div className="combo_box">
            <div className="tag">
              <p>PROMO</p>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <div className="content-wrapper">
                  <div className="heading-wrapper">
                    <h2>March Promo</h2>
                  </div>
                  <h3>Buy Both and Save Even More!</h3>
                  <p>
                    To kick off our "Unlimited" Plan, for the Month of March get
                    both
                  </p>
                  <div className="button-group">
                    <a href="#">Buy Now</a>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="both-package">
                  <div className="box">
                    <h3>Unlimited Orientation Plan</h3>
                    <h4>(50 Users)</h4>
                  </div>
                  <span className="plus">+</span>
                  <div className="box">
                    <h3>Unlimited IP Advanced Plan</h3>
                    <h4>(15 Users)</h4>
                  </div>
                </div>
                <div className="cobo-price-box">
                  <h3>Combo Price</h3>
                  <h2>$49.95 a Month Unlimited</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* <!-- Combo Package End Here --> */}

      <LoginModal
        isOpen={loginModal}
        toggle={() => setLoginModal(!loginModal)}
        // cb={() => {
        //   setLoginModal(false);
        //   setTimeout(() => {
        //     onBuy(buyingPackageId);
        //   }, 1000);
        // }}
      />

      <AddPaymentMethodModal
        isOpen={addPaymentMethodModal}
        toggle={() => setAddPaymentMethodModal(!addPaymentMethodModal)}
        // cb={() => {
        //   setAddPaymentMethodModal(!addPaymentMethodModal);
        //   setTimeout(() => {
        //     onBuy(buyingPackageId);
        //   }, 1000);
        // }}
      />

      {/* Footer Start Here */}
      <Footer />
      {/* Footer End Here */}
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  paymentMethods: state.paymentMethods,
});

const mapDispatchToProps = (dispatch) => ({
  updateProfile: async (user) => dispatch(await UpdateProfileAction(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Subscription);
