import axios from "axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import FormException from "./exceptions/FormException";

const config = {
  primaryColor: "#f6a309",
  showSuccess: (message) => {
    const MySwal = withReactContent(Swal);
    MySwal.fire({ title: "Success", text: message, icon: "success" });
  },
  showError: (message) => {
    const MySwal = withReactContent(Swal);
    MySwal.fire({ title: "Error", text: message, icon: "error" });
  },
  defaultHeaders: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
  bearerHeaders: (token) => ({
    ...config.defaultHeaders,
    Authorization: `Bearer ${token}`,
  }),
  bearerMultipartHeaders: (token) => ({
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  }),
  getApi: async (endpoint, headers, errorCb = null) => {
    try {
      let url = "";
      if (endpoint.includes("http")) {
        url = endpoint;
      } else {
        url = process.env.REACT_APP_API_BASE_URI + endpoint;
      }
      const response = await fetch(url, { headers });
      const res = await response.json();
      // if (response.status === 401) {
      //   const MySwal = withReactContent(Swal);
      //   const { isConfirmed } = await MySwal.fire({
      //     title: "Error",
      //     text: "Please log in or sign up first",
      //     icon: "error",
      //   });
      // }
      if (res.success) {
        return res.data;
      } else {
        if (errorCb) {
          errorCb();
          return false;
        }
        config.showError(res.message);
        if (res.errors) {
          throw new FormException(res.errors);
        }
        return false;
      }
    } catch (e) {
      console.log(e);
    }
  },
  postApi: async (endpoint, headers, data, showAlert = true) => {
    try {
      let url = "";
      if (endpoint.includes("http")) {
        url = endpoint;
      } else {
        url = process.env.REACT_APP_API_BASE_URI + endpoint;
      }
      let res = await axios.post(url, data, { headers });
      if (res.status >= 400) {
        config.showError(res.data.message);
        if (res.data.errors) {
          throw new FormException(res.data.errors);
        }
        return false;
      } else {
        if (showAlert) {
          config.showSuccess(res.data.message);
        }
        return res.data.data;
      }
    } catch (e) {
      if (e.name === "AxiosError") {
        let res = e.response.data;
        config.showError(res.message);
        if (res.errors) {
          throw new FormException(res.errors);
        }
        return false;
      } else if (e instanceof FormException) {
        throw new FormException(e.errors);
      }
    }
  },
  putApi: async (endpoint, headers, data) => {
    try {
      let url = "";
      if (endpoint.includes("http")) {
        url = endpoint;
      } else {
        url = process.env.REACT_APP_API_BASE_URI + endpoint;
      }
      let res = await axios.put(url, data, { headers });
      if (res.status >= 400) {
        config.showError(res.data.message);
        if (res.data.errors) {
          throw new FormException(res.data.errors);
        }
        return false;
      } else {
        config.showSuccess(res.data.message);
        return res.data.data;
      }
    } catch (e) {
      if (e.name === "AxiosError") {
        let res = e.response.data;
        config.showError(res.message);
        if (res.errors) {
          throw new FormException(res.errors);
        }
        return false;
      }
    }
  },
  deleteApi: async (endpoint, headers, showAlert = true) => {
    try {
      let url = "";
      if (endpoint.includes("http")) {
        url = endpoint;
      } else {
        url = process.env.REACT_APP_API_BASE_URI + endpoint;
      }
      let res = await axios.delete(url, { headers });
      if (res.status >= 400) {
        config.showError(res.data.message);
        if (res.data.errors) {
          throw new FormException(res.data.errors);
        }
        return false;
      } else {
        if (showAlert) {
          config.showSuccess(res.data.message);
        }
        return res.data.data;
      }
    } catch (e) {
      if (e.name === "AxiosError") {
        let res = e.response.data;
        config.showError(res.message);
        if (res.errors) {
          throw new FormException(res.errors);
        }
        return false;
      }
    }
  },
  returnAvatar: (user) => {
    if (user.avatar) {
      if (user.avatar.includes("http")) {
        return user.avatar;
      } else {
        return process.env.REACT_APP_ASSETS_URI + "/" + user.avatar;
      }
    } else {
      return (
        "https://ui-avatars.com/api/?name=" +
        user.full_name +
        "&background=random&size=256&rounded=true"
      );
    }
  },
  pickFile: (cb, accept = "image/*") => {
    let picker = document.getElementById("picker");
    picker.setAttribute("accept", accept);
    picker.onchange = cb;
    picker.click();
  },
  returnCardImage: (brand = "visa") => {
    switch (brand) {
      case "Visa":
        return require("./assets/img/visa.png");
      case "MasterCard":
        return require("./assets/img/mc.png");
      default:
        return require("./assets/img/visa.png");
    }
  },
  numberFormat: (number, fractionDigits = 2) => {
    if (number) {
      return number.toLocaleString("us", {
        minimumFractionDigits: fractionDigits,
        maximumFractionDigits: fractionDigits,
      });
    } else {
      return 0;
    }
  },
  returnImage: (fileName) => {
    if (fileName) {
      if (fileName.includes("http")) {
        return fileName;
      } else {
        return process.env.REACT_APP_ASSETS_URI + "/" + fileName;
      }
    }
    return (
      "https://ui-avatars.com/api/?name=" +
      fileName +
      "&background=random&size=512&rounded=true"
    );
  },
  downloadFile: (url, fileName) => {
    fetch(url, {
      method: "get",
      mode: "no-cors",
      referrerPolicy: "no-referrer",
    })
      .then((res) => res.blob())
      .then((res) => {
        const aElement = document.createElement("a");
        // if (fileName) {
        aElement.setAttribute("download", fileName);
        // } else {
        //   aElement.setAttribute("download");
        // }
        const href = URL.createObjectURL(res);
        aElement.href = href;
        // aElement.setAttribute('href', href);
        aElement.setAttribute("target", "_blank");
        aElement.click();
        URL.revokeObjectURL(href);
      });
  },
};

export default config;
