import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Logo } from "../../constant/Index";
import MyInput from "../../components/MyInput";
import MySpinner from "../../components/MySpinner";
import { connect } from "react-redux";
import { RegisterAction } from "../../redux/actions/auth";
import FormException from "../../exceptions/FormException";

const Register = ({ register }) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [facility, setFacility] = useState("");

  const [errors, setErrors] = useState({});

  const [spinner, setSpinner] = useState(false);

  const submit = async () => {
    try {
      setSpinner(true);
      await register(
        firstName,
        lastName,
        email,
        password,
        passwordConfirmation,
        phone,
        facility
      );
      setSpinner(false);
      setErrors({});
      setFirstName("");
      setLastName("");
      setEmail("");
      setPassword("");
      setPasswordConfirmation("");
      setPhone("");
      setFacility("");
    } catch (e) {
      if (e instanceof FormException) {
        console.log(e.errors);
        setErrors(e.errors);
      } else {
        console.log(e);
      }
      setSpinner(false);
    }
  };

  return (
    <>
      {/* Login Sec Start Here */}
      <section className="auth login">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-7 col-md-6 px-0">
              <div className="row mx-0 h-100">
                <div className="col-xl-6 col-lg-10 col-md-10 col-sm-8 mx-auto">
                  <div className="auth_start">
                    <div className="logo">
                      <Link to="/">
                        <img src={Logo} alt="logo" className="img-fluid" />
                      </Link>
                    </div>
                    <div className="wraper">
                      <div className="top_text">
                        <h2>Register</h2>
                        <h4>
                          Welcome to{" "}
                          <span className="colored-txt">
                            Infection Prevention
                          </span>
                        </h4>
                        <p>
                          Already a Member ? <Link to="/login">Login</Link>
                        </p>
                      </div>
                      <div className="form_start mt-5">
                        <div className="row">
                          <div className="col-md-6">
                            <MyInput
                              inputId="first_name"
                              value={firstName}
                              onChange={(e) => setFirstName(e.target.value)}
                              placeholder="First name"
                              errors={errors.first_name}
                            />
                          </div>
                          <div className="col-md-6">
                            <MyInput
                              inputId="last_name"
                              value={lastName}
                              onChange={(e) => setLastName(e.target.value)}
                              placeholder="Last name"
                              errors={errors.last_name}
                            />
                          </div>
                          <div className="col-md-6">
                            <MyInput
                              type="email"
                              inputId="email"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                              placeholder="Email"
                              errors={errors.email}
                            />
                          </div>
                          <div className="col-md-6">
                            <MyInput
                              type="tel"
                              inputId="phone"
                              value={phone}
                              onChange={(e) => setPhone(e.target.value)}
                              placeholder="Phone"
                              errors={errors.phone}
                            />
                          </div>
                          <div className="col-md-6">
                            <MyInput
                              type="password"
                              inputId="password"
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                              placeholder="Password"
                              errors={errors.password}
                            />
                          </div>
                          <div className="col-md-6">
                            <MyInput
                              type="password"
                              inputId="password_confirmation"
                              value={passwordConfirmation}
                              onChange={(e) =>
                                setPasswordConfirmation(e.target.value)
                              }
                              placeholder="Confirm password"
                              errors={errors.password_confirmation}
                            />
                          </div>
                          <div className="col-md-12">
                            <MyInput
                              type="text"
                              inputId="facility"
                              value={facility}
                              onChange={(e) => setFacility(e.target.value)}
                              placeholder="Enter Facility Name"
                              errors={errors.facility}
                            />
                          </div>
                        </div>
                        <div className="form_row mt-3">
                          <button
                            className="primary-btn center d-block w-100"
                            type="button"
                            onClick={submit}
                            disabled={spinner}
                          >
                            {spinner ? <MySpinner /> : "Register"}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-5 col-md-6 px-0">
              <div className="side-image">
                <div className="main_img">
                  <Link to="/" className="d-block">
                    <img src={Logo} className="img-fluid" alt="" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Login Sec End Here */}
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  register: async (
    firstName,
    lastName,
    email,
    password,
    passwordConfirmation,
    phone,
    facility
  ) =>
    dispatch(
      await RegisterAction(
        firstName,
        lastName,
        email,
        password,
        passwordConfirmation,
        phone,
        facility
      )
    ),
});

export default connect(null, mapDispatchToProps)(Register);
