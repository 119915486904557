import React, { useState } from "react";
import {
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import MyInputFormGroup from "./MyInputFormGroup";

const ExportPdfModal = ({ isOpen, toggle, onExport }) => {
  const [pdfLogo, setPdfLogo] = useState({});
  const [pdfRef, setPdfRef] = useState("");
  const [pdfVersion, setPdfVersion] = useState("");
  const [pdfOwner, setPdfOwner] = useState("");
  const [pdfAuthoriser, setPdfAuthoriser] = useState("");
  const [pdfHeaderText, setPdfHeaderText] = useState("");
  const [pdfIssueDate, setPdfIssueDate] = useState("");

  const [errors, setErrors] = useState({});

  const [spinner, setSpinner] = useState(false);

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg">
      <ModalHeader toggle={toggle}>Export Docx</ModalHeader>
      <ModalBody>
        <Row>
          <Col>
            <MyInputFormGroup
              showLabel
              placeholder="Enter Owner"
              required={false}
              label="Owner"
              type="text"
              value={pdfOwner}
              onChange={(e) => setPdfOwner(e.target.value)}
              inputId="owner"
              errors={errors.owner}
            />
            <MyInputFormGroup
              showLabel
              placeholder="Enter Version"
              required={false}
              label="Version"
              type="text"
              value={pdfVersion}
              onChange={(e) => setPdfVersion(e.target.value)}
              inputId="version"
              errors={errors.version}
            />
            <MyInputFormGroup
              showLabel
              placeholder="Enter Issue Date"
              required={false}
              label="Issue Date"
              type="date"
              value={pdfIssueDate}
              onChange={(e) => setPdfIssueDate(e.target.value)}
              inputId="issue_date"
              errors={errors.issue_date}
            />
            <MyInputFormGroup
              showLabel
              placeholder="Select Logo for PDF Export"
              required={true}
              label="Logo"
              type="file"
              value={pdfLogo.fileName && ""}
              onChange={(e) => {
                console.log(e.target.files[0]);
                setPdfLogo(e.target.files[0]);
              }}
              inputId="logo"
              errors={errors.logo}
            />
          </Col>
          <Col>
            <MyInputFormGroup
              showLabel
              placeholder="Enter Header Text"
              required={false}
              label="Header Text"
              type="text"
              value={pdfHeaderText}
              onChange={(e) => setPdfHeaderText(e.target.value)}
              inputId="header_text"
              errors={errors.header_text}
            />
            <MyInputFormGroup
              showLabel
              placeholder="Enter Ref"
              required={false}
              label="Ref"
              type="text"
              value={pdfRef}
              onChange={(e) => setPdfRef(e.target.value)}
              inputId="ref"
              errors={errors.ref}
            />
            <MyInputFormGroup
              showLabel
              placeholder="Enter Authoriser"
              required={false}
              label="Authoriser"
              type="text"
              value={pdfAuthoriser}
              onChange={(e) => setPdfAuthoriser(e.target.value)}
              inputId="authoriser"
              errors={errors.authoriser}
            />
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <button className="primary-btn" type="button" onClick={toggle}>
          Cancel
        </button>
        <button
          className="primary-btn"
          type="button"
          onClick={async () => {
            try {
              setSpinner(true);
              const errors = await onExport({
                logo: pdfLogo,
                owner: pdfOwner,
                authoriser: pdfAuthoriser,
                ref: pdfRef,
                version: pdfVersion,
                header_text: pdfHeaderText,
                issue_date: pdfIssueDate,
              });
              setSpinner(false);
              if (errors) {
                setErrors(errors);
              }
            } catch (e) {
              console.log(e);
            }
          }}
          disabled={spinner}
        >
          {spinner ? <Spinner /> : "Export"}
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default ExportPdfModal;
