import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import { Logo } from "../../constant/Index";
import "../../assets/css/dashboard.css";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import AddUserModal from "../../components/AddUserModal";
import config from "../../config";
import {
  ASSESSMENT_RESULT,
  COURSES_ENDPOINT,
  ENROLLMENTS_ENDPOINT,
  STUDENTS_ENDPOINT,
} from "../../endpoints";
import { connect } from "react-redux";
import Swal from "sweetalert2";

const StatsDashboard = ({ auth }) => {
  const [usermodal, setUsermodal] = useState(false);

  const [courses, setCourses] = useState([]);
  const [results, setResults] = useState({ assessment_result: [] });

  useEffect(() => {
    getCourses();
  }, []);

  const getCourses = async () => {
    const headers = config.bearerHeaders(auth.token);
    const res = await config.getApi(COURSES_ENDPOINT, headers);
    if (res) {
      setCourses(res.data);
    }
  };

  const getCourseResult = async (courseId) => {
    const headers = config.bearerHeaders(auth.token);
    const res = await config.getApi(ASSESSMENT_RESULT(courseId), headers);
    if (res) {
      setResults(res);
    }
  };

  const onAddUser = async (firstName, lastName, email, password, courseId) => {
    try {
      const headers = config.bearerHeaders(auth.token);
      const data = JSON.stringify({
        first_name: firstName,
        last_name: lastName,
        email,
        password,
      });
      const student = await config.postApi(STUDENTS_ENDPOINT, headers, data);
      if (student && student.id) {
        const data2 = JSON.stringify({
          user_id: student.id,
          course_id: courseId,
        });
        await config.postApi(ENROLLMENTS_ENDPOINT, headers, data2, false);
        return true;
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Error in adding Student!",
        });
        return false;
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      {/* Header Start Here */}
      <Header />
      {/* Header End Here */}

      {/* Stats Dashboard Start Here */}
      <section className="privacy_procedure stats-dashboard">
        <div className="container">
          <div className="heading_wrapper">
            <h2>Analytical Dashboard</h2>
          </div>
          <div className="row">
            <div className="col-lg-4">
              <div className="sidebar">
                <div className="logo-box">
                  <Link to="/">
                    <figure>
                      <img src={Logo} alt="logo" className="img-fluid" />
                    </figure>
                  </Link>
                </div>
                <div
                  className="nav flex-column nav-pills"
                  id="v-pills-tab"
                  role="tablist"
                  aria-orientation="vertical"
                >
                  {courses.map((course, index) => (
                    <a
                      key={`course_${course.id}`}
                      className={`nav-link ` + (index == 0 ? "" : "")}
                      data-toggle="pill"
                      href={`#v-pills-course-${course.id}`}
                      role="tab"
                      aria-selected="true"
                      onClick={() => getCourseResult(course.id)}
                    >
                      {course.title}
                    </a>
                  ))}
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="tab-content" id="v-pills-tabContent">
                {courses.map((course, index) => (
                  <div
                    key={`course_tab_content_${course.id}`}
                    className={`tab-pane fade ` + (index == 0 ? "" : "")}
                    id={`v-pills-course-${course.id}`}
                    role="tabpanel"
                  >
                    <div className="top-bar">
                      <div className="heading">
                        <h3>{course.title}</h3>
                      </div>
                      <div className="button-group">
                        {/* <button
                          type="button"
                          onClick={() => setUsermodal(true)}
                          className="add-user"
                        >
                          Add User
                        </button>
                        <button className="remove-user">Remove User</button> */}
                        <h4>
                          {course.enrollments_count}
                          <br />
                          <span>Current Users</span>
                        </h4>
                      </div>
                    </div>
                    <div className="score-table">
                      {/* <div className="button-group">
                        <button className="primary-btn report-btn">
                          Generate Report
                        </button>
                      </div> */}
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th className="user-email">Email</th>
                              <th className="user-name">Name</th>
                              <th className="secured-score">Score</th>
                            </tr>
                          </thead>
                          <tbody>
                            {results?.assessment_result?.map((result) => (
                              <tr key={`result_${course.id}_${result.id}`}>
                                <td className="user-email">
                                  {result?.lms_user?.email}
                                </td>
                                <td className="user-name">
                                  {result?.lms_user?.name}
                                </td>
                                <td className="secured-score">
                                  {result?.score}%
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <CircularProgressbar
                          value={results?.user_tested}
                          text={results?.user_tested}
                          styles={buildStyles({
                            // Colors
                            pathColor: "#f50000",
                            textColor: "#f50000",
                            trailColor: "#f5000029",
                            backgroundColor: "#3e98c7",
                          })}
                        />
                        <div className="txt text-center pt-4">
                          <h5>Percent of User Tested</h5>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <CircularProgressbar
                          value={results?.avg_score}
                          text={results?.avg_score}
                          styles={buildStyles({
                            // Colors
                            pathColor: "green",
                            textColor: "#008000",
                            trailColor: "#0080002e",
                            backgroundColor: "#3e98c7",
                          })}
                        />
                        <div className="txt text-center pt-4">
                          <h5>Average Score</h5>
                        </div>
                      </div>
                      {/* <div className="col-md-4">
                        <CircularProgressbar
                          value={23}
                          text={23}
                          styles={buildStyles({
                            // Colors
                            pathColor: "#3e98c7",
                            textColor: "#3e98c7",
                            trailColor: "#3e98c74f",
                            backgroundColor: "#3e98c7",
                          })}
                        />
                        <div className="txt text-center pt-4">
                          <h5>Average Improvement 1st and 2nd test</h5>
                        </div>
                      </div> */}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Stats Dashboard End Here */}

      {/* Add User Start Modal */}
      <AddUserModal
        isOpen={usermodal}
        toggle={() => setUsermodal(!usermodal)}
        courses={courses}
        onSubmit={onAddUser}
      />
      {/* Add User End Modal */}

      {/* Footer Start Here */}
      <Footer />
      {/* Footer End Here */}
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(StatsDashboard);
