import React, { useEffect, useState } from "react";
import "../../assets/css/policy-procedure.css";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import { CKEditor } from "ckeditor4-react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import config from "../../config";
import { USER_POLICIES } from "../../endpoints";
import { Spinner } from "reactstrap";
import ExportPdfModal from "../../components/ExportPdfModal";
import Swal from "sweetalert2";

const ckInstances = {};

const PrivacyProcedure = ({ authToken }) => {
  const { id } = useParams();

  const [loading, setLoading] = useState(true);
  const [spinner, setSpinner] = useState(false);
  const [policy, setPolicy] = useState({});

  const [exportPdfModal, setExportPdfModal] = useState(false);

  const getUserPolicy = async () => {
    const headers = config.bearerHeaders(authToken);
    const res = await config.getApi(USER_POLICIES + "/" + id, headers);
    if (res) {
      setPolicy(res);
      setLoading(false);
    }
  };

  useEffect(() => {
    getUserPolicy();
  }, []);

  const onSave = async (sectionId) => {
    if (ckInstances[sectionId]) {
      setSpinner(true);
      const headers = config.bearerHeaders(authToken);
      const data = JSON.stringify({
        section_id: sectionId,
        policy_content: ckInstances[sectionId].getData(),
      });
      const res = await config.putApi(USER_POLICIES + "/" + id, headers, data);
      setSpinner(false);
      if (res) {
        // await getUserPolicy();
      }
    } else {
      alert("Instance is not Ready!");
    }
  };

  const onDelete = async (sectionId) => {
    const value = await Swal.fire({
      icon: "warning",
      title: "Delete Confirmation",
      text: "Are you sure you want to delete this section?",
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: "Yes",
    });
    if (value.isConfirmed) {
      setSpinner(true);
      const headers = config.bearerHeaders(authToken);
      const res = await config.deleteApi(
        USER_POLICIES + "/" + id + "/" + sectionId,
        headers,
        false
      );
      setSpinner(false);
      if (res) {
        window.location.reload();
      }
    }
  };

  const exportPdf = async (data) => {
    const formData = new FormData();
    formData.append("type", "pdf");
    formData.append("version", data.version);
    formData.append("logo", data.logo);
    formData.append("ref", data.ref);
    formData.append("owner", data.owner);
    formData.append("authoriser", data.authoriser);
    formData.append("issue_date", data.issue_date);
    formData.append("header_text", data.header_text);
    const headers = config.bearerMultipartHeaders(authToken);
    const res = await config.postApi(
      USER_POLICIES + "/" + id + "/export",
      headers,
      formData,
      false
    );
    if (res) {
      window.open(res.url, "Download");
    }
  };

  const exportDocx = async (data) => {
    const formData = new FormData();
    formData.append("type", "docx");
    formData.append("version", data.version);
    formData.append("logo", data.logo);
    formData.append("ref", data.ref);
    formData.append("owner", data.owner);
    formData.append("authoriser", data.authoriser);
    formData.append("issue_date", data.issue_date);
    formData.append("header_text", data.header_text);
    const headers = config.bearerMultipartHeaders(authToken);
    const res = await config.postApi(
      USER_POLICIES + "/" + id + "/export",
      headers,
      formData,
      false
    );
    if (res) {
      window.open(res.url, "Download");
    }
  };

  if (loading) {
    return (
      <>
        <Header />
        <section
          style={{
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            width: "100%",
            height: 400,
            display: "flex",
          }}
        >
          <Spinner style={{ color: config.primaryColor }} />
        </section>
        <Footer />
      </>
    );
  }
  return (
    <>
      {/* Header Start Here */}
      <Header />
      {/* Header End Here */}

      {/* P&P Sec Start Here */}
      <section className="privacy_procedure">
        <div className="container">
          {/* <div className="heading_wrapper">
                        <h2><span className="colored">Infection Prevention</span><br />Smart P&P</h2>
                    </div> */}
          <div className="row">
            <div className="col-lg-3">
              <div className="sidebar">
                <div className="title">
                  <h3>Policy Name</h3>
                </div>
                <div
                  className="nav flex-column nav-pills"
                  id="v-pills-tab"
                  role="tablist"
                  aria-orientation="vertical"
                >
                  {policy.direct_sections.map((section, key) => {
                    if (section.child_sections.length > 0) {
                      return (
                        <>
                          <a
                            className={`nav-link ${key == 0 ? "active" : ""}`}
                            id={`v-pills-${section.id}-tab`}
                            data-toggle="pill"
                            href={`#v-pills-policy${section.id}`}
                            role="tab"
                            aria-controls={`v-pills-policy${section.id}`}
                            aria-selected="true"
                          >
                            {section.name}
                          </a>
                          {section.child_sections.map((childSection) => (
                            <a
                              className="nav-link"
                              id={`v-pills-${childSection.id}-tab`}
                              data-toggle="pill"
                              href={`#v-pills-policy${childSection.id}`}
                              role="tab"
                              aria-controls={`v-pills-policy${childSection.id}`}
                              aria-selected="true"
                            >
                              {childSection.name}
                            </a>
                          ))}
                        </>
                      );
                    } else {
                      return (
                        <a
                          className={`nav-link ${key == 0 ? "active" : ""}`}
                          id={`v-pills-${section.id}-tab`}
                          data-toggle="pill"
                          href={`#v-pills-policy${section.id}`}
                          role="tab"
                          aria-controls={`v-pills-policy${section.id}`}
                          aria-selected="true"
                        >
                          {section.name}
                        </a>
                      );
                    }
                  })}
                </div>
              </div>
              {/* <div className="alert alert-warning mt-2" role="alert">
                This is an alert with{" "}
                <a href="#" className="alert-link">
                  an example link
                </a>
                .
              </div> */}
            </div>
            <div className="col-lg-9">
              <div className="tab-content" id="v-pills-tabContent">
                {policy.direct_sections.map((section, key) => {
                  if (section.child_sections.length > 0) {
                    return (
                      <>
                        <div
                          className={`tab-pane fade ${
                            key == 0 ? "active show" : ""
                          }`}
                          id={`v-pills-policy${section.id}`}
                          role="tabpanel"
                          aria-labelledby={`v-pills-policy${section.id}-tab`}
                        >
                          <div className="topbar">
                            <div className="title">
                              <h3>
                                <span>{section.name}</span>
                              </h3>
                            </div>
                            <div className="button-group">
                              <button
                                type="button"
                                onClick={() => onSave(section.id)}
                                className="save-btn primary-btn action-btns"
                                disabled={spinner}
                              >
                                {spinner ? <Spinner size="sm" /> : "Save"}
                              </button>
                              <button
                                type="button"
                                onClick={() => onDelete(section.id)}
                                className="save-btn primary-btn action-btns"
                                disabled={spinner}
                              >
                                {spinner ? <Spinner size="sm" /> : "Delete"}
                              </button>
                              {/* <button
                                className="export-btn primary-btn action-btns"
                                type="button"
                                onClick={() => setExportPdfModal(true)}
                              >
                                Export PDF
                              </button> */}
                              <button
                                className="export-btn primary-btn action-btns"
                                type="button"
                                onClick={() => setExportPdfModal(true)}
                              >
                                Export Docx
                              </button>
                            </div>
                          </div>
                          <div className="policy-content">
                            <div className="name">
                              <h4>{section.name}</h4>
                            </div>
                            <CKEditor
                              config={{
                                height: 600,
                              }}
                              name={`editor_${section.id}`}
                              initData={section.content}
                              onInstanceReady={({ editor }) => {
                                ckInstances[section.id] = editor;
                              }}
                              onFocus={({ editor }) => {
                                ckInstances[section.id] = editor;
                              }}
                              style={{
                                height: 600,
                              }}
                            />
                          </div>
                        </div>
                        {section.child_sections.map((childSection) => (
                          <div
                            className="tab-pane fade"
                            id={`v-pills-policy${childSection.id}`}
                            role="tabpanel"
                            aria-labelledby={`v-pills-policy${childSection.id}-tab`}
                          >
                            <div className="topbar">
                              <div className="title">
                                <h3>
                                  <span>{childSection.name}</span>
                                </h3>
                              </div>
                              <div className="button-group">
                                <button
                                  type="button"
                                  onClick={() => onSave(childSection.id)}
                                  className="save-btn primary-btn action-btns"
                                  disabled={spinner}
                                >
                                  {spinner ? <Spinner size="sm" /> : "Save"}
                                </button>
                                <button
                                  type="button"
                                  onClick={() => onDelete(section.id)}
                                  className="save-btn primary-btn action-btns"
                                  disabled={spinner}
                                >
                                  {spinner ? <Spinner size="sm" /> : "Delete"}
                                </button>
                                <button
                                  onClick={() => setExportPdfModal(true)}
                                  className="export-btn primary-btn action-btns"
                                  type="button"
                                >
                                  Export Docx
                                </button>
                              </div>
                            </div>
                            <div className="policy-content">
                              <div className="name">
                                <h4>{childSection.name}</h4>
                              </div>
                              <CKEditor
                                config={{
                                  height: 600,
                                }}
                                name={`editor_${childSection.id}`}
                                initData={childSection.content}
                                onInstanceReady={({ editor }) => {
                                  ckInstances[childSection.id] = editor;
                                }}
                                onFocus={({ editor }) => {
                                  ckInstances[section.id] = editor;
                                }}
                                style={{
                                  height: 600,
                                }}
                              />
                            </div>
                          </div>
                        ))}
                      </>
                    );
                  } else {
                    return (
                      <div
                        className={`tab-pane fade ${
                          key == 0 ? "active show" : ""
                        }`}
                        id={`v-pills-policy${section.id}`}
                        role="tabpanel"
                        aria-labelledby={`v-pills-policy${section.id}-tab`}
                      >
                        <div className="topbar">
                          <div className="title">
                            <h3>
                              <span>{section.name}</span>
                            </h3>
                          </div>
                          <div className="button-group">
                            <button
                              type="button"
                              onClick={() => onSave(section.id)}
                              className="save-btn primary-btn action-btns"
                              disabled={spinner}
                            >
                              {spinner ? <Spinner size="sm" /> : "Save"}
                            </button>
                            <button
                              type="button"
                              onClick={() => onDelete(section.id)}
                              className="save-btn primary-btn action-btns"
                              disabled={spinner}
                            >
                              {spinner ? <Spinner size="sm" /> : "Delete"}
                            </button>
                            <button
                              type="button"
                              onClick={() => setExportPdfModal(true)}
                              className="export-btn primary-btn action-btns"
                            >
                              Export Docx
                            </button>
                          </div>
                        </div>
                        <div className="policy-content">
                          <div className="name">
                            <h4>{section.name}</h4>
                          </div>
                          <CKEditor
                            config={{
                              height: 600,
                            }}
                            name={`editor_${section.id}`}
                            initData={section.content}
                            onInstanceReady={({ editor }) => {
                              ckInstances[section.id] = editor;
                            }}
                            onFocus={({ editor }) => {
                              ckInstances[section.id] = editor;
                            }}
                            style={{
                              height: 600,
                            }}
                          />
                        </div>
                      </div>
                    );
                  }
                })}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* P&P Sec End Here */}

      <ExportPdfModal
        isOpen={exportPdfModal}
        toggle={() => setExportPdfModal(!exportPdfModal)}
        onExport={exportDocx}
      />

      {/* Footer Start Here */}
      <Footer />
      {/* <footer>
        <div className="container">
          <div className="copyright">
            <p className="rights">© Copyright Bug Control, 2023</p>
            <div className="img-box">
              <a href="#">
                <figure>
                  <img src={kwdiImg} alt="kwdi" className="img-fluid" />
                </figure>
              </a>
            </div>
          </div>
        </div>
      </footer> */}
      {/* Footer End Here */}
    </>
  );
};

const mapStateToProps = (state) => ({
  authToken: state.auth.token,
});

export default connect(mapStateToProps)(PrivacyProcedure);
