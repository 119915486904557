import React, { useEffect } from "react";
import {
  HashRouter as BrowserRouter,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import About from "../pages/About/About";
import BcSystem from "../pages/BcSystem/BcSystem";
import Contact from "../pages/Contact/Contact";
import Home from "../pages/Home/Home";
import LeadCourse from "../pages/LeadCourse/LeadCourse";
import TermsConditions from "../pages/Legals/TermsConditions";
import News from "../pages/News/News";
import NewsDetail from "../pages/News/NewsDetail";
import Packages from "../pages/Packages/Packages";
import AmsCompendiums from "../pages/Shop/AmsCompendiums";
import Cart from "../pages/Shop/Cart";
import Checkout from "../pages/Shop/Checkout";
import ControlGuide from "../pages/Shop/ControlGuide";
import EducationalResources from "../pages/Shop/EducationalResources";
import FlipChart from "../pages/Shop/FlipChart";
import HandHygiene from "../pages/Shop/HandHygiene";
import ProductDetail from "../pages/Shop/ProductDetail";
import Shop from "../pages/Shop/Shop";
import ThankYou from "../pages/ThankYou/ThankYou";
import Login from "../pages/Auth/Login";
import Register from "../pages/Auth/Register";
import ForgotPass from "../pages/Auth/ForgotPass";
import CreatePass from "../pages/Auth/CreatePass";
import { connect } from "react-redux";
import Profile from "../pages/Profile/Profile";
import NotFound from "../pages/NotFound";
import { GetCategoriesAction } from "../redux/actions/categories";
import Category from "../pages/Category";
import { GetLocationAction } from "../redux/actions/location";
import UserDashboard from "../pages/Dashboard/UserDashboard";
import PrivacyProcedure from "../pages/PrivacyProcedure/PrivacyProcedure";
import StatsDashboard from "../pages/StatsDashboard/StatsDashboard";
import Policies from "../pages/Policies/Policies";
import Students from "../pages/Dashboard/Students";
import Subscription from "../pages/Subscription/Subscription";
import Faqs from "../pages/Faqs/Faq_page";
import ProtectedRoute from "../components/ProtectedRoute";

const PublicRoutes = ({ authToken, auth, getCategories, getLocation }) => {
  useEffect(() => {
    getLocation();
    getCategories().then();
  }, []);
  return (
    <>
      {/* All Routes Start Here */}
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/packages" element={<Packages />} />
          <Route path="/modules" element={<Subscription />} />
          <Route path="/lead-course" element={<LeadCourse />} />
          <Route path="/shop" element={<Shop />} />
          <Route path="/product-detail/:id" element={<ProductDetail />} />
          <Route path="/product-cart" element={<Cart />} />
          <Route
            path="/educational-resource"
            element={<EducationalResources />}
          />
          <Route path="/flipcharts" element={<FlipChart />} />
          <Route path="/control-guide" element={<ControlGuide />} />
          <Route path="/ams-compendiums" element={<AmsCompendiums />} />
          <Route path="/hand-hygiene" element={<HandHygiene />} />
          <Route path="/about-us" element={<About />} />
          <Route path="/bc-systems" element={<BcSystem />} />
          <Route path="/news" element={<News />} />
          <Route path="/news-detail/:id" element={<NewsDetail />} />
          <Route path="/contact-us" element={<Contact />} />
          <Route path="/faqs" element={<Faqs />} />
          <Route path="/terms-and-condition" element={<TermsConditions />} />
          <Route path="*" element={<NotFound />} />
          <Route path="/category/:slug" element={<Category />} />
          <Route path="/stats-dashboard" element={<StatsDashboard />} />
          {/* <Route path="/checkout" element={<Checkout />} />
          <Route path="/thank-you" element={<ThankYou />} /> */}
          <Route
            path="/checkout"
            element={
              <ProtectedRoute>
                <Checkout />
              </ProtectedRoute>
            }
          />
          <Route
            path="/thank-you"
            element={
              <ProtectedRoute>
                <ThankYou />
              </ProtectedRoute>
            }
          />
          <Route
            path="/profile"
            element={
              <ProtectedRoute>
                <Profile />
              </ProtectedRoute>
            }
          />
          {/* Auth */}
          {!authToken ? (
            <>
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route path="/forgot-password" element={<ForgotPass />} />
              <Route path="/reset-password" element={<CreatePass />} />
            </>
          ) : (
            <>
              {auth.user.id == auth.user.facility.facility_owner_id ? (
                <>
                  <Route path="/user-dashboard" element={<UserDashboard />} />
                  <Route path="/all-policies" element={<Policies />} />
                  <Route
                    path="/policy-procedure/:id"
                    element={<PrivacyProcedure />}
                  />
                  <Route path="/students" element={<Students />} />
                </>
              ) : null}
            </>
          )}
          {/* <Route
            path="*"
            element={(props) => {
              return <div>abcd</div>;
            }}
          /> */}
        </Routes>
      </BrowserRouter>
      {/* All Routes End Here */}
    </>
  );
};

const mapStateToProps = (state) => ({
  authToken: state.auth.token,
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  getCategories: async () => dispatch(await GetCategoriesAction()),
  getLocation: async () => dispatch(await GetLocationAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PublicRoutes);
