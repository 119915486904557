import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import AssignCourseModal from "../../components/AssignCourseModal";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import ViewCourseModal from "../../components/ViewCourseModal";
import config from "../../config";
import {
  COURSES_ENDPOINT,
  ENROLLMENTS_ENDPOINT,
  STUDENTS_ENDPOINT,
} from "../../endpoints";

const Students = ({ authToken }) => {
  const [assignCourseModal, setAssignCourseModal] = useState(false);
  const [viewCourseModal, setViewCourseModal] = useState(false);

  const [students, setStudents] = useState([]);

  const [studentDetail, setStudentDetail] = useState({});

  const [courses, setCourses] = useState([]);

  useEffect(() => {
    getStudents();
    getCourses();
  }, []);

  const getCourses = async () => {
    const headers = config.bearerHeaders(authToken);
    const res = await config.getApi(COURSES_ENDPOINT, headers);
    if (res) {
      setCourses(res.data);
    }
  };

  const getStudents = async () => {
    const headers = config.bearerHeaders(authToken);
    const res = await config.getApi(STUDENTS_ENDPOINT, headers);
    if (res) {
      setStudents(res);
    }
  };

  const getStudentDetail = async (id, type = "view") => {
    const headers = config.bearerHeaders(authToken);
    const res = await config.getApi(STUDENTS_ENDPOINT + "/" + id, headers);
    if (res) {
      setStudentDetail(res);
      if (type === "view") {
        setViewCourseModal(true);
      } else if (type === "assign") {
        setAssignCourseModal(true);
      }
    }
  };

  const onEnroll = async (courseId) => {
    const courseIds = courseId.map((singleCourse) => singleCourse.value);
    const headers = config.bearerHeaders(authToken);
    const data = JSON.stringify({
      user_id: studentDetail.user.id,
      course_ids: courseIds,
    });
    const res = await config.postApi(ENROLLMENTS_ENDPOINT, headers, data);
    if (res) {
      console.log(res);
    }
  };

  const onDelete = async (userId) => {
    const headers = config.bearerHeaders(authToken);
    const res = await config.deleteApi(
      STUDENTS_ENDPOINT + "/" + userId,
      headers
    );
    if (res) {
      console.log(res);
      getStudents();
    }
  };

  return (
    <>
      {/* Header Start Here */}
      <Header />
      {/* Header End Here */}

      {/* Students Sec Start Here */}
      <section className="students_sec">
        <div className="container">
          <div className="heading-wrapper mb-5 text-center">
            <h2>Users</h2>
          </div>
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th className="name">Name</th>
                  <th className="email">Email</th>
                  {/* <th className="number">Number</th> */}
                  <th className="action">Actions</th>
                </tr>
              </thead>
              <tbody>
                {students.map((student) => (
                  <tr key={`student_${student.id}`}>
                    <td className="name">
                      {student.first_name + " " + student.last_name}
                    </td>
                    <td className="email">{student.user?.email}</td>
                    {/* <td className="number">{student.user?.mobile_number}</td> */}
                    <td className="action">
                      <button
                        className="assign-course"
                        onClick={() => getStudentDetail(student.id, "assign")}
                      >
                        Assign Course
                      </button>
                      <button
                        className="assign-course"
                        onClick={() => getStudentDetail(student.id)}
                      >
                        View Assigned Courses
                      </button>
                      <button
                        className="assign-course"
                        onClick={() => onDelete(student.user.id)}
                      >
                        Delete Student
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </section>
      {/* Students Sec End Here */}

      {/* Assign Course Modal */}
      <AssignCourseModal
        isOpen={assignCourseModal}
        toggle={() => setAssignCourseModal(!assignCourseModal)}
        courses={courses}
        enrollments={studentDetail?.user?.enrollments || []}
        onEnroll={onEnroll}
      />
      {/* Assign Course Modal */}

      {/* View Assigned Course Modal */}
      <ViewCourseModal
        isOpen={viewCourseModal}
        toggle={() => setViewCourseModal(!viewCourseModal)}
        enrollments={studentDetail?.user?.enrollments || []}
      />
      {/* View Assigned Course Modal */}

      {/* Footer Start Here */}
      <Footer />
      {/* Footer End Here */}
    </>
  );
};

const mapStateToProps = (state) => ({
  authToken: state.auth.token,
});

export default connect(mapStateToProps)(Students);
