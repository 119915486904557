import React, { useState } from "react";
import config from "../../config";
import { NEWSLETTER_ENDPOINT } from "../../endpoints";

const Newsletter = () => {
  const [email, setEmail] = useState("");

  const onSubmit = async () => {
    try {
      await config.postApi(
        NEWSLETTER_ENDPOINT,
        config.defaultHeaders,
        JSON.stringify({ email })
      );
      setEmail("");
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      {/* Newseltter Sec Start Here */}
      <section className="newsletter">
        <div className="container">
          <div className="heading-wrapper wow animate__animated animate__fadeInDown">
            <h2>Subscribe to our newsletter</h2>
            <p>
              {/* For helpful leads, new tools and more delivered right to your inbox. */}
              Get helpful tips, free quizzes and the latest in aged care news.
            </p>
          </div>
          <div className="newsletter-box wowa animate__animated animate__fadeInUp">
            {/* <form action=""> */}
            <div className="form-group">
              <input
                type="email"
                placeholder="Enter your email"
                className="form-control"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="button-group">
              <button className="primary-btn" type="button" onClick={onSubmit}>
                Submit
              </button>
            </div>
            {/* </form> */}
          </div>
        </div>
      </section>
      {/* Newseltter Sec End Here */}
    </>
  );
};

export default Newsletter;
