import React, { useEffect, useState } from "react";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import "../../assets/css/checkout.css";
import { useNavigate } from "react-router-dom";
import "../../assets/css/thank-you.css";
import { connect } from "react-redux";
import config from "../../config";
import AddressSelector from "../../components/AddressSelector";
import MyInputFormGroup from "../../components/MyInputFormGroup";
import PaymentMethodSelector from "../../components/PaymentMethodSelector";
import {
  AddAddressAction,
  GetAddressesAction,
} from "../../redux/actions/addresses";
import FormException from "../../exceptions/FormException";
import OverlaySpinner from "../../components/OverlaySpinner";
import {
  GetPaymentMethodsAction,
  AddPaymentMethodAction,
} from "../../redux/actions/paymentMethods";
import AddCard from "../../components/AddCard";
import { CheckoutAction } from "../../redux/actions/orders";
import Swal from "sweetalert2";

const Checkout = ({
  cart,
  addresses,
  paymentMethods,
  addAddress,
  getAddresses,
  getPaymentMethods,
  addPaymentMethod,
  checkout,
}) => {
  const navigate = useNavigate();

  const [paymentMethodId, setPaymentMethodId] = useState();
  const [addressId, setAddressId] = useState();
  const [subtotal, setSubtotal] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [shipping, setShipping] = useState(0);
  const [total, setTotal] = useState(0);

  const [line1, setLine1] = useState("");
  const [line2, setLine2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");
  const [country, setCountry] = useState("");

  const [errors, setErrors] = useState({});

  const [spinner, setSpinner] = useState(false);

  const unauthenticatedCallback = async () => {
    await Swal.fire({
      icon: "error",
      title: "Error",
      text: "Please log in or sign up first",
    });
    navigate("/login");
  };

  useEffect(() => {
    Promise.all([
      getAddresses(unauthenticatedCallback),
      getPaymentMethods(unauthenticatedCallback),
    ]).then();
  }, []);

  useEffect(() => {
    let localSubtotal = 0;
    cart.map((cartItem) => {
      localSubtotal += cartItem.quantity * cartItem.product.price;
    });
    setSubtotal(localSubtotal);
    setTotal(localSubtotal + shipping - discount);
  }, [cart]);

  const onAddAddress = async () => {
    try {
      setSpinner(true);
      await addAddress({
        line_1: line1,
        line_2: line2,
        city,
        state,
        zip,
        country,
      });
      setSpinner(false);
      setErrors({});
      emptyAddressFields();
    } catch (e) {
      setSpinner(false);
      if (e instanceof FormException) {
        setErrors(e.errors);
      }
    }
  };

  const emptyAddressFields = () => {
    setLine1("");
    setLine2("");
    setCity("");
    setState("");
    setZip("");
    setCountry("");
  };

  const onAddCard = async (sourceId) => {
    try {
      setSpinner(true);
      await addPaymentMethod(sourceId);
      setSpinner(false);
    } catch (e) {
      setSpinner(false);
      console.log(e);
    }
  };

  const onCheckout = async () => {
    try {
      setSpinner(true);
      let orderItems = cart.map((cartItem) => {
        return {
          product_id: cartItem.product_id,
          quantity: cartItem.quantity,
        };
      });
      let order_id = await checkout({
        payment_method_id: paymentMethodId,
        address_id: addressId,
        items: orderItems,
        total,
        subtotal,
      });
      setSpinner(false);
      if (order_id) {
        navigate("/thank-you?order_id=" + order_id);
      }
    } catch (e) {
      setSpinner(false);
    }
    // console.log({ addressId, paymentMethodId, total, subtotal });
  };

  return (
    <>
      {/* Header Start Here */}
      <Header />
      {/* Header End Here */}

      {/* Checkout Sec Start Here */}
      <section className="checkout">
        <div className="container">
          <div className="main-heading-wrapper">
            <h3>Order Checkout</h3>
          </div>
          <div className="row">
            <div className="col-lg-6"></div>
            <div className="col-lg-6"></div>
          </div>
          <form action="/Thank_you">
            <div className="row">
              <div className="col-lg-6">
                <div className="first-top-bar">
                  <h3>Shipment Details</h3>
                </div>
                <div className="saved-address">
                  <div className="title">
                    <h5>Use Saved Addresses</h5>
                  </div>
                  {/* <!-- Saved Address --> */}
                  {addresses.map((address) => (
                    <AddressSelector
                      address={address}
                      key={`address_${address.id}`}
                      selected={addressId == address.id}
                      onChange={(id) => setAddressId(id)}
                    />
                  ))}
                  {/* <!-- Saved Address --> */}
                </div>
                <label className="new-add-container">
                  {" "}
                  <span className="txt pl-4">Add New Address</span>
                  <input
                    type="radio"
                    hidden
                    name="Addressradio"
                    className="radioInput"
                  />
                  <span className="checkmark">
                    <div className="radio-btn">
                      <span></span>
                    </div>
                    <div className="add-new-address">
                      <div className="row">
                        {/* <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="fname">First Name</label>
                            <input
                              type="text"
                              id="fname"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="lname">Last Name</label>
                            <input
                              type="text"
                              id="lname"
                              className="form-control"
                            />
                          </div>
                        </div> */}
                        {/* <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="phone">Phone</label>
                            <input
                              type="text"
                              id="phone"
                              className="form-control"
                            />
                          </div>
                        </div> */}
                        {/* <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="email">Email</label>
                            <input
                              type="text"
                              id="email"
                              className="form-control"
                            />
                          </div>
                        </div> */}
                        <div className="col-md-12">
                          <MyInputFormGroup
                            label="Address"
                            onChange={(e) => setLine1(e.target.value)}
                            value={line1}
                            inputId="line_1"
                            errors={errors.line_1}
                            showLabel
                          />
                        </div>
                        <div className="col-md-12">
                          <MyInputFormGroup
                            label="Suite, Apartment, etc"
                            onChange={(e) => setLine2(e.target.value)}
                            value={line2}
                            inputId="line_2"
                            errors={errors.line_2}
                            showLabel
                          />
                        </div>
                        <div className="col-md-12">
                          <MyInputFormGroup
                            label="City"
                            onChange={(e) => setCity(e.target.value)}
                            value={city}
                            inputId="city"
                            errors={errors.city}
                            showLabel
                          />
                        </div>
                        <div className="col-md-4">
                          <MyInputFormGroup
                            label="Country / Region"
                            onChange={(e) => setCountry(e.target.value)}
                            value={country}
                            inputId="country"
                            errors={errors.country}
                            showLabel
                          />
                        </div>
                        <div className="col-md-4">
                          <MyInputFormGroup
                            label="State"
                            onChange={(e) => setState(e.target.value)}
                            value={state}
                            inputId="state"
                            errors={errors.state}
                            showLabel
                          />
                        </div>
                        <div className="col-md-4">
                          <MyInputFormGroup
                            label="Zip Code"
                            onChange={(e) => setZip(e.target.value)}
                            value={zip}
                            inputId="zip"
                            errors={errors.zip}
                            showLabel
                          />
                        </div>
                        {/* <div className="col-md-12">
                          <div className="form-check">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="exampleCheckssd"
                            />
                            <label
                              className="form-check-label label_ssd"
                              htmlFor="exampleCheckssd"
                            >
                              Save Shipping Details
                            </label>
                          </div>
                        </div> */}
                        <div className="col-md-12">
                          <div className="button-group mt-4">
                            <button
                              className="primary-btn"
                              type="button"
                              onClick={onAddAddress}
                            >
                              Add Address
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </span>
                </label>
                <div className="payment-details">
                  <div className="heading">
                    <h3>Payment Details</h3>
                  </div>
                  <div className="saved-payment-method">
                    <h5>Use Saved Payments</h5>
                    {/* <!-- Saved payments Method --> */}
                    {paymentMethods.map((paymentMethod) => (
                      <PaymentMethodSelector
                        key={`payment_method_${paymentMethod.id}`}
                        paymentMethod={paymentMethod}
                        onChange={(pmId) => setPaymentMethodId(pmId)}
                        selected={paymentMethodId == paymentMethod.id}
                      />
                    ))}
                    <div className="add-new-method">
                      <div className="form-check py-3">
                        <input
                          type="radio"
                          className="form-check-input"
                          id="exampleCheckanp"
                        />
                        <label
                          className="form-check-label anp_label"
                          htmlFor="exampleCheckanp"
                        >
                          Add New Payment
                        </label>
                      </div>
                      <AddCard
                        key="add_card"
                        onAdded={(sourceId) => onAddCard(sourceId)}
                      />
                    </div>
                    {/* <!-- Saved payments Method --> */}
                  </div>
                </div>
              </div>
              <div className="col-lg-1"></div>
              <div className="col-lg-5">
                {/* <div className="second-top-bar">
                  <div className="title pb-4">
                    <h3>Order Summery</h3>
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      placeholder="Enter Promo Code"
                      className="form-control"
                    />
                    <button type="button">SUBMIT</button>
                  </div>
                </div> */}
                <div className="cart-product-detail">
                  <div className="title">
                    <h3>Products Details</h3>
                  </div>
                  <div className="checkout-products">
                    {cart.map((cartItem) => (
                      <div
                        className="products"
                        key={`cart_${cartItem.product_id}`}
                      >
                        <div className="abt-product">
                          <div className="img-box">
                            <figure>
                              <img
                                src={config.returnImage(cartItem.product.cover)}
                                alt="123"
                              />
                            </figure>
                          </div>
                          <div className="pro-dtl">
                            <h5 className="name">{cartItem.product.name}</h5>
                          </div>
                        </div>
                        <div className="product-price">
                          <h4 className="price">
                            $
                            {config.numberFormat(
                              cartItem.product.price * cartItem.quantity
                            )}
                          </h4>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="pricing-details">
                    <div className="item-total">
                      <h4 className="property">Item Total :</h4>
                      <h4 className="value">
                        ${config.numberFormat(subtotal)}
                      </h4>
                    </div>
                    <div className="item-total">
                      <h4 className="property">Shipment & Delivery :</h4>
                      <h4 className="value">
                        ${config.numberFormat(shipping)}
                      </h4>
                    </div>
                    {discount ? (
                      <div className="item-total">
                        <h4 className="property">Promo Code Applied :</h4>
                        <h4 className="value">
                          -${config.numberFormat(discount)}
                        </h4>
                      </div>
                    ) : null}
                  </div>

                  <div className="order-total">
                    <h3 className="property">Order Total</h3>
                    <h3 className="value">${config.numberFormat(total)}</h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="border-last"></div>
            <div className="confirm-orderDv">
              <div className="checkbox">
                <input
                  type="checkbox"
                  className="form-control"
                  id="for_checkbox"
                />
                <label htmlFor="for_checkbox" className="for_checkbox_label">
                  Proident, sunt in culpa qui officia deserunt mollit anim id
                  est laborum.
                </label>
              </div>
              <div className="button-group">
                <button type="button" onClick={onCheckout}>
                  Confirm Order
                </button>
              </div>
            </div>
          </form>
        </div>
      </section>
      {/* Checkout Sec End Here */}

      {spinner ? <OverlaySpinner /> : null}

      {/* Footer Start Here */}
      <Footer />
      {/* Footer End Here */}
    </>
  );
};

const mapStateToProps = (state) => ({
  cart: state.cart,
  addresses: state.addresses,
  paymentMethods: state.paymentMethods,
});

const mapDispatchToProps = (dispatch) => ({
  addAddress: async (address) => dispatch(await AddAddressAction(address)),
  getAddresses: async (erroCb = null) =>
    dispatch(await GetAddressesAction(erroCb)),
  getPaymentMethods: async (erroCb = null) =>
    dispatch(await GetPaymentMethodsAction((erroCb = null))),
  addPaymentMethod: async (sourceId) =>
    dispatch(await AddPaymentMethodAction(sourceId)),
  checkout: async (order) => dispatch(await CheckoutAction(order)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Checkout);
