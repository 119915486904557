import React, { useState } from "react";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from "reactstrap";
import FormException from "../exceptions/FormException";
import MyInput from "./MyInput";
import MyInputFormGroup from "./MyInputFormGroup";
import MyMultiSelect from "./MyMultiSelect";

const AddUserModal = ({ isOpen, toggle, courses, onSubmit }) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [courseId, setCourseId] = useState([]);

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const onSubmitFn = async () => {
    try {
      setLoading(true);
      const courseIds = courseId.map((singleCourse) => singleCourse.value);
      const response = await onSubmit(
        firstName,
        lastName,
        email,
        password,
        courseIds
      );
      setLoading(false);
      if (response) {
        setFirstName("");
        setLastName("");
        setEmail("");
        setPassword("");
        setCourseId([]);
        toggle();
      }
    } catch (e) {
      setLoading(false);
      if (e instanceof FormException) {
        setErrors(e.errors);
      }
    }
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        centered={true}
        className="user-modal"
        size="lg"
      >
        <ModalHeader toggle={toggle}>Add User</ModalHeader>
        <ModalBody>
          <div className="row">
            <div className="col-md-6">
              <MyInputFormGroup
                placeholder="First Name"
                inputId="fname"
                type="text"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                errors={errors.first_name}
              />
            </div>
            <div className="col-md-6">
              <MyInputFormGroup
                placeholder="Last Name"
                inputId="lname"
                type="text"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                errors={errors.last_name}
              />
            </div>
            <div className="col-md-6">
              <MyInputFormGroup
                placeholder="Email"
                inputId="email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                errors={errors.email}
              />
            </div>
            <div className="col-md-6">
              <MyInputFormGroup
                placeholder="Password"
                inputId="password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                errors={errors.password}
              />
            </div>
            <div className="col-md-12">
              {/* <MyInputFormGroup
                multiple
                inputClassNames={["form-control"]}
                placeholder="Select"
                type="select"
                options={[
                  { label: "Select Module", value: null },
                  ...courses.map((course) => {
                    return {
                      label: course.title,
                      value: course.id,
                    };
                  }),
                ]}
                errors={errors.course_id}
                onChange={(e) => setCourseId(e.target.value)}
              /> */}
              <MyMultiSelect
                options={courses.map((course) => {
                  return {
                    label: course.title,
                    value: course.id,
                  };
                })}
                placeholder={"Select Modules"}
                label={"Module"}
                value={courseId}
                onValueChange={(e) => {
                  // let values = e.map((selectOption) => selectOption.value);
                  setCourseId(e);
                }}
              />
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="button-group">
            <button type="button" className="primary-btn mr-3" onClick={toggle}>
              {"Cancel"}
            </button>
            <button
              type="button"
              className="primary-btn "
              onClick={onSubmitFn}
              disabled={loading}
            >
              {loading ? <Spinner /> : "Submit"}
            </button>
          </div>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default AddUserModal;
