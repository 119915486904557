import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import config from "../../config";
import { ADD_TO_CART } from "../../redux/actions/types";

const Product = (props) => {
  const addToCart = (product) => {
    props.onAddToCart({
      product_id: product.id,
      product: product,
      quantity: 1,
    });
    Swal.fire({
      icon: "success",
      title: "Added",
      text: `${product.name} has been added to cart!`,
    });
  };

  const ProductData = props.ProductData;
  return (
    <>
      {/* Product Start Here */}
      {ProductData?.map((data, index) => (
        <div className="col-lg-4 col-md-6" key={index}>
          <Link to={`/product-detail/${data.id}`}>
            <div className="product-box">
              <div className="img-box">
                <figure>
                  <img
                    src={config.returnImage(data?.cover)}
                    alt={data.cover}
                    className="img-fluid"
                  />
                </figure>
              </div>
              <div className="detail">
                <h4>{data?.name}</h4>
                <h5 className="price">
                  $
                  {Number.isInteger(data?.price)
                    ? data?.price
                    : data?.price.toFixed(2)}
                </h5>
                <div className="button-group">
                  <button
                    className="btn"
                    type="button"
                    onClick={() => addToCart(data)}
                  >
                    <i className="fa fa-shopping-basket" aria-hidden="true"></i>
                  </button>
                </div>
              </div>
            </div>
          </Link>
        </div>
      ))}
      {/* Product End Here */}
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  onAddToCart: (cartItem) => dispatch({ type: ADD_TO_CART, payload: cartItem }),
});

export default connect(null, mapDispatchToProps)(Product);
