export const LOGIN_ENDPOINT = "/login";
export const REGISTER_ENDPOINT = "/register";
export const CATEGORIES_ENDPOINT = "/categories";
export const PRODUCTS_ENDPOINT = "/products";
export const PRODUCT_REVIEWS_ENDPOINT = (id) => `/products/${id}/reviews`;
export const ADDRESSES_ENDPOINT = "/addresses";
export const PAYMENT_METHODS_ENDPOINT = "/payment-methods";
export const PACKAGES_ENDPOINT = "/packages";
export const SUBSCRIPTIONS_ENDPOINT = "/subscriptions";
export const BLOGS_ENDPOINT = "/blogs";

export const CONTACT_ENDPOINT = "/contact/contact";
export const ORDERS_ENDPOINT = "/orders";

export const ME_ENDPOINT = "/me";
export const UPDATE_PROFILE_ENDPOINT = "/update-profile";
export const CHANGE_PASSWORD_ENDPOINT = "/change-password";

export const SEND_RESET_EMAIL_ENDPOINT = "/password/email";
export const RESET_PASSWORD_ENDPOINT = "/password/reset";

export const DEFAULT_POLICIES = "/policies";
export const USER_POLICIES = "/user-policies";
export const MODULES_ENDPOINT = "/modules";
export const FILE_DOWNLOAD_ENDPOINT = (id) => `/files/${id}/download`;

export const CENTRAL_DASHBOARD_ENDPOINT = "/central-dashboard";

// FOR LMS
export const STUDENTS_ENDPOINT = "/students";
export const ENROLLMENTS_ENDPOINT = "/enrollments";
// export const COURSES_ENDPOINT =
// process.env.REACT_APP_LMS_API_BASE_URI + "/courses";
export const COURSES_ENDPOINT = "/courses";
export const ASSESSMENT_RESULT = (course_id) =>
  `/courses/${course_id}/assessment-results`;

export const NEWSLETTER_ENDPOINT = "/newsletter-emails";
