import React, { useState } from "react";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from "reactstrap";
import MyInputFormGroup from "./MyInputFormGroup";

const PolicyModal = ({ isOpen, toggle, policies, onAdd }) => {
  const [policyId, setPolicyId] = useState(null);
  const [loading, setLoading] = useState(false);

  return (
    <>
      <Modal
        className="policy-modal"
        isOpen={isOpen}
        toggle={toggle}
        centered={true}
      >
        <ModalHeader>Add Policy</ModalHeader>
        <ModalBody>
          <div className="row">
            <div className="col-md-12">
              <MyInputFormGroup
                placeholder="Last Name"
                inputId="Policyselect"
                type="select"
                options={[
                  { label: "Select Policy", value: null },
                  ...policies.map((policy) => {
                    return {
                      label: policy.name,
                      value: policy.id,
                    };
                  }),
                ]}
                value={policyId}
                onChange={(e) => setPolicyId(e.target.value)}
              />
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            className="primary-btn"
            onClick={() => {
              setLoading(true);
              onAdd(policyId);
              setLoading(false);
              setPolicyId(null);
              toggle();
            }}
            type="button"
            disabled={loading}
          >
            {loading ? <Spinner /> : "Submit"}
          </button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default PolicyModal;
