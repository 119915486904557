import Logo from "../../src/assets/img/logo.svg";
import ProductIcon1 from "../../src/assets/img/range-product1.png";
import ProductIcon2 from "../../src/assets/img/range-product2.png";
import ProductIcon3 from "../../src/assets/img/range-product3.png";
import MacScreen from "../../src/assets/img/mac-screen.png";
import Brand1 from "../../src/assets/img/brand1.png";
import Brand2 from "../../src/assets/img/brand2.png";
import Brand3 from "../../src/assets/img/brand3.jpg";
import Brand4 from "../../src/assets/img/brand4.png";
import Brand6 from "../../src/assets/img/brand6.png";
import Brand7 from "../../src/assets/img/brand7.jpg";
import Brand8 from "../../src/assets/img/brand8.png";
import Brand9 from "../../src/assets/img/brand9.png";
import Brand10 from "../../src/assets/img/brand10.png";
import Brand11 from "../../src/assets/img/brand11.jpg";
import Brand12 from "../../src/assets/img/brand12.png";
import Brand13 from "../../src/assets/img/brand13.png";
import Brand14 from "../../src/assets/img/brand14.png";
import Brand15 from "../../src/assets/img/brand15.png";
import BlogImg1 from "../../src/assets/img/blog1.jpg";
import BlogImg2 from "../../src/assets/img/blog2.jpg";
import BlogImg3 from "../../src/assets/img/blog3.jpg";
import BlogImg4 from "../../src/assets/img/blog4.jpg";
import BlogImg5 from "../../src/assets/img/blog5.jpg";
import BlogImg6 from "../../src/assets/img/blog6.jpg";
import BlogImg7 from "../../src/assets/img/blog7.jpg";
import BlogImg8 from "../../src/assets/img/blog8.jpg";
import BlogImg9 from "../../src/assets/img/blog9.jpg";
import BlogImg10 from "../../src/assets/img/blog10.jpg";
import BlogImg11 from "../../src/assets/img/blog11.jpg";
import BlogImg12 from "../../src/assets/img/blog12.jpg";
import kwdiImg from "../../src/assets/img/kwd.svg";
import CourseImg from "../../src/assets/img/ip-lead-img.png";
import ValueIcon1 from "../../src/assets/img/value-icon1.svg";
import ValueIcon2 from "../../src/assets/img/value-icon2.svg";
import ValueIcon3 from "../../src/assets/img/value-icon3.svg";
import ValueIcon4 from "../../src/assets/img/value-icon4.svg";
import ValueIcon5 from "../../src/assets/img/value-icon5.svg";
import ContactImage from "../../src/assets/img/contact-img.png";
import InnerBannerImage from "../../src/assets/img/innerbanner.jpg";
import InnerBannerImage2 from "../../src/assets/img/inner-banner2.jpg";
import Product1 from "../../src/assets/img/product1.jpg";
import Product2 from "../../src/assets/img/product2.jpg";
import Product3 from "../../src/assets/img/product3.jpg";
import Product4 from "../../src/assets/img/product4.jpg";
import Product5 from "../../src/assets/img/product5.jpg";
import Product6 from "../../src/assets/img/product6.png";
import Product7 from "../../src/assets/img/product7.jpg";
import Product8 from "../../src/assets/img/product8.jpg";
import Product9 from "../../src/assets/img/product9.png";
import Product10 from "../../src/assets/img/product10.png";
import Product11 from "../../src/assets/img/product11.png";
import Product12 from "../../src/assets/img/product12.png";
import Product13 from "../../src/assets/img/product13.jpg";
import Product14 from "../../src/assets/img/product14.jpg";
import Product15 from "../../src/assets/img/product15.jpg";
import Product16 from "../../src/assets/img/product16.jpg";
import BannerImage3 from "../../src/assets/img/banner-img3.png";
import EducationalResourceImg from "../../src/assets/img/educational-resource-img.jpg";
import HandHygieneImg from "../../src/assets/img/hand-hygiene.jpg";
import Icon1 from "../../src/assets/img/icon1.png";
import Icon2 from "../../src/assets/img/icon2.png";
import StoryImg from "../../src/assets/img/our-story.jpg";
import PurposeImg from "../../src/assets/img/purpose.jpg";
import ApproachImg from "../../src/assets/img/approach.jpg";
import BcBannerImg from "../../src/assets/img/bc-banner.jpg";
import BcIcon1 from "../../src/assets/img/box-icon1.png";
import BcIcon2 from "../../src/assets/img/box-icon2.png";
import Step1Img from "../../src/assets/img/step1.png";
import Step2Img from "../../src/assets/img/step2.png";
import Step3Img from "../../src/assets/img/step3.png";
import Step4Img from "../../src/assets/img/step4.png";
import IpacIcon from "../../src/assets/img/haist-icon.png";
import NewsBanner from "../../src/assets/img/new-banner.jpg";
import ContactBanner from "../../src/assets/img/contact-banner.jpg";
import LegalBanner from "../../src/assets/img/contact-info-img.png";
import review_img from "../../src/assets/img/review1.png";
import visa from "../../src/assets/img/visa.png";
import ProfileImage from "../../src/assets/img/profile.png";
import NotFoundImg from "../../src/assets/img/page-not-found.png";
import ExportPdf from "../../src/assets/img/pdf-sample.pdf";
import BuyBtn from "../../src/assets/img/buy-now-btn.png";

export const constants = {
  ADDRESS_TYPE_BILIING: "Billing",
  ADDRESS_TYPE_SHIPPING: "Shipping",
  contact_info_fallback: "AU",
  contact_info: {
    NZ: {
      phone: "0800 849 211",
      email: "info@infectioncontrol.care",
      address: "BCP Trust: PO Box 42, Fairlie, New Zealand 7949",
      map_link: "https://goo.gl/maps/v27kXpg9SwwmZQ4a7",
      footer_content:
        "Bug Control recognises the unique role of Māori as Tangata Whenua and embraces Te Tiriti o Waitangi recognising Māori as tino rangitiratanga of Aotearoa/New Zealand while embracing the three guiding principles of the Treaty – Partnership Participation and Protection. We will endeavour to implement bicultural policies and practices that incorporate and value Māori cultural concepts, values and practices.",
    },
    AU: {
      phone: "(+61) 1 800 849 211",
      email: "info@infectioncontrol.care",
      address: "Sydney, New South Wales",
      map_link: "https://goo.gl/maps/iC4i9yo5sjKbkgXf7",
      footer_content:
        "In the spirit of reconciliation Bug Control acknowledges the Traditional Custodians of the country throughout Australia and their connections to land, sea and community. We pay our respect to their elders past and present and extend that respect to all Aboriginal and Torres Strait Islander peoples today.",
    },
  },
  links: {
    // kb: "https://bug-control.document360.io/",
    // lms: "https://infectioncontrol.talentlms.com/index",
    kb: "https://bestpractice.care",
    lms: "https://lms.bestpractice.care",
  },
};

export {
  Logo,
  ProductIcon1,
  ProductIcon2,
  ProductIcon3,
  MacScreen,
  Brand1,
  Brand2,
  Brand3,
  Brand4,
  Brand6,
  Brand7,
  Brand8,
  Brand9,
  Brand10,
  Brand11,
  Brand12,
  Brand13,
  Brand14,
  Brand15,
  BlogImg1,
  BlogImg2,
  BlogImg3,
  BlogImg4,
  BlogImg5,
  BlogImg6,
  BlogImg7,
  BlogImg8,
  BlogImg9,
  BlogImg10,
  BlogImg11,
  BlogImg12,
  kwdiImg,
  CourseImg,
  ValueIcon1,
  ValueIcon2,
  ValueIcon3,
  ValueIcon4,
  ValueIcon5,
  ContactImage,
  InnerBannerImage,
  InnerBannerImage2,
  Product1,
  Product2,
  Product3,
  Product4,
  Product5,
  Product6,
  Product7,
  Product8,
  Product9,
  Product10,
  Product11,
  Product12,
  Product13,
  Product14,
  Product15,
  Product16,
  BannerImage3,
  EducationalResourceImg,
  HandHygieneImg,
  Icon1,
  Icon2,
  StoryImg,
  PurposeImg,
  ApproachImg,
  BcBannerImg,
  BcIcon1,
  BcIcon2,
  Step1Img,
  Step2Img,
  Step3Img,
  Step4Img,
  IpacIcon,
  NewsBanner,
  ContactBanner,
  LegalBanner,
  review_img,
  visa,
  ProfileImage,
  NotFoundImg,
  ExportPdf,
  BuyBtn,
};
