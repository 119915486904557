import config from "../../config";
import { USER_POLICIES } from "../../endpoints";
import { ADD_POLICY, GET_POLICIES } from "./types";

export const GetPoliciesAction = () => async (dispatch, getState) => {
  const headers = config.bearerHeaders(getState().auth.token);
  const res = await config.getApi(USER_POLICIES, headers);
  if (res) {
    dispatch({ type: GET_POLICIES, payload: res });
  }
};

export const AddPolicyAction = (policyId) => async (dispatch, getState) => {
  const headers = config.bearerHeaders(getState().auth.token);
  const res = await config.postApi(
    USER_POLICIES,
    headers,
    JSON.stringify({
      policy_procedure_id: policyId,
    })
  );
  if (res) {
    dispatch({ type: ADD_POLICY, payload: res });
  }
};
