import React from "react";
import { connect } from "react-redux";
import { Navigate } from "react-router-dom";

const ProtectedRoute = ({ children, authToken }) => {
  if (authToken) {
    return children;
  } else {
    return <Navigate to={"/register"} />;
  }
};

const mapStateToProps = (state) => ({
  authToken: state.auth.token,
});

export default connect(mapStateToProps)(ProtectedRoute);
