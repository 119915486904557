import React, { useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import "../../assets/css/dashboard.css";
import { Link } from "react-router-dom";
import AllModals from "../../components/AllModals/AllModals";
import AddUserModal from "../../components/AddUserModal";
import config from "../../config";
import {
  CENTRAL_DASHBOARD_ENDPOINT,
  COURSES_ENDPOINT,
  ENROLLMENTS_ENDPOINT,
  FILE_DOWNLOAD_ENDPOINT,
  MODULES_ENDPOINT,
  STUDENTS_ENDPOINT,
} from "../../endpoints";
import { connect } from "react-redux";
import SubscriptionBox from "../../components/SubscriptionBox";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { BuyBtn, constants } from "../../constant/Index";
import Swal from "sweetalert2";

const UserDashboard = ({ auth }) => {
  const [courses, setCourses] = useState({ data: [] });
  const [modules, setModules] = useState([]);

  const [userDashboard, setUserDashboard] = useState(null);

  const getCourses = async () => {
    const headers = config.bearerHeaders(auth.token);
    const res = await config.getApi(COURSES_ENDPOINT, headers);
    if (res) {
      setCourses(res);
    }
  };

  const getModules = async () => {
    const headers = config.bearerHeaders(auth.token);
    const res = await config.getApi(MODULES_ENDPOINT, headers);
    if (res) {
      setModules(res);
    }
  };

  const getDashbaord = async () => {
    const headers = config.bearerHeaders(auth.token);
    const res = await config.getApi(CENTRAL_DASHBOARD_ENDPOINT, headers);
    if (res) {
      setUserDashboard(res);
    }
  };

  useEffect(() => {
    if (auth.user.active_subscription) {
      getCourses();
    }
    getDashbaord();
    getModules();
  }, []);

  const onAddUser = async (firstName, lastName, email, password, courseIds) => {
    try {
      const headers = config.bearerHeaders(auth.token);
      const data = JSON.stringify({
        first_name: firstName,
        last_name: lastName,
        email,
        password,
      });
      const student = await config.postApi(STUDENTS_ENDPOINT, headers, data);
      if (student && student.id) {
        const data2 = JSON.stringify({
          user_id: student.id,
          course_ids: courseIds,
        });
        await config.postApi(ENROLLMENTS_ENDPOINT, headers, data2, false);
        return true;
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Error in adding Student!",
        });
        return false;
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onDownload = async (file) => {
    try {
      const headers = config.bearerHeaders(auth.token);
      const res = await config.getApi(FILE_DOWNLOAD_ENDPOINT(file.id), headers);
      if (res) {
        // config.downloadFile(res,);
        window.open(res, "Download");
      }
    } catch (e) {
      console.log(e);
    }
  };

  // const [downloadLinks, setDownloadLinks] = useState("");
  const [downloadFiles, setDownloadFiles] = useState([]);
  const [downloadLinksModal, setDownLoadLinksModal] = useState(false);

  const onShowModule = async (module) => {
    setDownloadFiles(module.aws_files);
    setDownLoadLinksModal(true);
  };

  const [usermodal, setUsermodal] = useState(false);
  return <>
    <AllModals />

    {/* Header Start Here */}
    <Header />
    {/* Header End Here */}

    {/* Dashborad Start Here */}
    <section className="dashboard_sec">
      <div className="container">
        {/* <div className="heading-wrapper">
          <h2>Infection Prevention</h2>
          <h4>Subscription Dashboard</h4>
        </div> */}
        {/* {auth.user.active_subscription && (
          <div className="row">
            <div className="col-lg-6">
              <div className="button-group topBtns">
                <Link to="/stats-dashboard" className="primary-btn">
                  Reports
                </Link>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="button-group topBtns text-right">
                <Link className="primary-btn mr-3" to={"/students"}>
                  All Users
                </Link>
                <button
                  type="button"
                  onClick={() => setUsermodal(true)}
                  className="primary-btn"
                >
                  <i className="fa fa-user-plus" aria-hidden="true"></i> Add
                  User
                </button>
              </div>
            </div>
          </div>
        )} */}
        <div className="row">
          <div className="col-lg-3">
          <SubscriptionBox
              title={"Policies"}
              paid={auth.user.active_subscription}
              navigateTo={"/all-policies"}
            />
            {modules.map((singleModule) => {

              return singleModule.name == 'AMS Compendiums' ?
                    <SubscriptionBox
                        title={singleModule.name}
                        paid={singleModule.has_access}
                        onClick={
                          singleModule.module_type == "download_links"
                              ? () => onShowModule(singleModule)
                              : null
                        }
                        user_add_module={singleModule.module_type != "download_links"}
                        addUserModal={() => setUsermodal(true)}
                        // navigateTo={"/"}
                    />
                  :
                  <></>
            })}
            {/* {userDashboard && (
              <div className="subscription-box paid">
                <div className="inner-box">
                  <div className="txt">
                    <marquee>
                      <Link to={userDashboard.promotional_link}>
                        <h4>{userDashboard.promotional_text}</h4>
                      </Link>
                    </marquee>
                  </div>
                  <div className="button-group">
                    <a href={userDashboard.promotional_link}>
                      <img src={BuyBtn} alt="btn-img" className="img-fluid" />
                    </a>
                  </div>
                </div>
              </div>
            )} */}

          </div>
          <div className="col-lg-6">
            {userDashboard && <iframe
                width="100%"
                height="350"
                src={userDashboard.video_link}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>}
          </div>
          <div className="col-lg-3">
            <SubscriptionBox
              title={"Knowledge Base"}
              navigateTo={constants.links.kb}
              paid={auth.user.kb_access}
            />
            <SubscriptionBox
              title={"Courses"}
              navigateTo="https://infectioncontrol.talentlms.com/index"
              paid={auth.user.lms_access}
            />
          </div>
          {/* <div className="col-lg-3">
            <div className="subscription-box paid">
              <div className="inner-box">
                <div className="txt">
                  <marquee>
                    <Link to="#">
                      <h4>
                        This is where we promote a special product each week..
                      </h4>
                    </Link>
                  </marquee>
                </div>
              </div>
            </div>
          </div> */}
          {/*{modules.map((singleModule) => {*/}

          {/*  return singleModule.name == 'AMS Compendiums' ?*/}
          {/*      <div className="col-lg-3" key={`module_${singleModule.id}`}>*/}
          {/*        <SubscriptionBox*/}
          {/*            title={singleModule.name}*/}
          {/*            paid={singleModule.has_access}*/}
          {/*            onClick={*/}
          {/*              singleModule.module_type == "download_links"*/}
          {/*                  ? () => onShowModule(singleModule)*/}
          {/*                  : null*/}
          {/*            }*/}
          {/*            user_add_module={singleModule.module_type != "download_links"}*/}
          {/*            addUserModal={() => setUsermodal(true)}*/}
          {/*            // navigateTo={"/"}*/}
          {/*        />*/}
          {/*      </div>*/}
          {/*      :*/}
          {/*      <></>*/}
          {/*})}*/}
        </div>
      </div>
    </section>
    {/* Dashborad End Here */}

    {/* Add User Start Modal */}
    <AddUserModal
      isOpen={usermodal}
      toggle={() => setUsermodal(!usermodal)}
      courses={courses.data}
      onSubmit={onAddUser}
    />
    {/* Add User End Modal */}

    <Modal
      isOpen={downloadLinksModal}
      toggle={() => setDownLoadLinksModal(!downloadLinksModal)}
    >
      <ModalHeader>Download links</ModalHeader>
      <ModalBody>
        <div>
          <ul>
            {downloadFiles.map((downloadFile) => <li key={`download_file_${downloadFile.id}`} className="mt-2">
                {downloadFile.file_name}
                <button
                  className="btn btn-primary btn-sm ml-3"
                  type="button"
                  onClick={() => onDownload(downloadFile)}
                >
                  Download
                </button>
              </li>)}
          </ul>
        </div>
      </ModalBody>
      <ModalFooter>
        <button
          type="button"
          className="btn btn-danger"
          onClick={() => setDownLoadLinksModal(false)}
        >
          Close
        </button>
      </ModalFooter>
    </Modal>

    {/* Footer Start Here */}
    <Footer />
    {/* Footer End Here */}
  </>;
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(UserDashboard);
