import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  Form,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from "reactstrap";
import config from "../config";
import { AddPaymentMethodAction } from "../redux/actions/paymentMethods";

const AddPaymentMethodModalInner = ({ isOpen, toggle, cb = null }) => {
  const stripe = useStripe();
  const elements = useElements();

  const dispatch = useDispatch();

  const [adding, setAdding] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setAdding(true);
      const { error, source } = await stripe.createSource(
        elements.getElement(CardElement),
        {
          type: "card",
        }
      );
      if (error) {
        setAdding(false);
        return false;
      }
      await dispatch(await AddPaymentMethodAction(source.id));
      setAdding(false);
      if (cb) {
        return cb();
      } else {
        return toggle();
      }
    } catch (exception) {
      console.log(exception);
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <Form onSubmit={handleSubmit}>
        <ModalHeader>Add Payment Method</ModalHeader>
        <ModalBody>
          <CardElement
            options={{
              style: {
                base: { fontSize: "20px" },
                complete: {
                  color: config.primaryColor,
                },
              },
            }}
          />
        </ModalBody>
        <ModalFooter>
          <button type="button" onClick={toggle} className="btn">
            Cancel
          </button>
          <button type="submit" className="primary-btn" disabled={adding}>
            {adding ? <Spinner size={"sm"} /> : "Add"}
          </button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

const AddPaymentMethodModal = (props) => {
  return (
    <Elements stripe={loadStripe(process.env.REACT_APP_STRIPE_PK)}>
      <AddPaymentMethodModalInner {...props} />
    </Elements>
  );
};

export default AddPaymentMethodModal;
