import React, { useEffect, useState } from "react";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import "../../assets/css/dashboard.css";
import PolicyModal from "../../components/PolicyModal";
import { connect } from "react-redux";
import {
  AddPolicyAction,
  GetPoliciesAction,
} from "../../redux/actions/policies";
import config from "../../config";
import { DEFAULT_POLICIES, USER_POLICIES } from "../../endpoints";
import { Spinner } from "reactstrap";
import { useNavigate } from "react-router-dom";

const Policies = ({ authToken, policies, getPolicies, addPolicy }) => {
  const navigate = useNavigate();

  const [privacyModal, setPrivacyModal] = useState(false);

  const [defaultPolicies, setDefaultPolicies] = useState([]);

  const [spinner, setSpinner] = useState(false);

  useEffect(() => {
    getPolicies();
    getDefaultPolicies();
  }, []);

  const getDefaultPolicies = async () => {
    const headers = config.bearerHeaders(authToken);
    const res = await config.getApi(DEFAULT_POLICIES, headers);
    if (res) {
      setDefaultPolicies(res);
    }
  };

  const onAdd = async (policyId) => {
    try {
      await addPolicy(policyId);
    } catch (e) {
      console.log(e);
    }
  };

  const deletePolicy = async (policyId) => {
    try {
      setSpinner(true);
      const headers = config.bearerHeaders(authToken);
      await config.deleteApi(USER_POLICIES + "/" + policyId, headers);
      await getPolicies();
      setSpinner(false);
    } catch (e) {
      setSpinner(false);
      console.log(e);
    }
  };

  return (
    <>
      {/* Header Start Here */}
      <Header />
      {/* Header End Here */}

      {/* Policies Table */}
      <section className="policies_sec">
        <div className="container">
          <div className="button-group">
            <button
              className="primary-btn"
              onClick={() => setPrivacyModal(true)}
            >
              Add New
            </button>
          </div>
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th className="sno">ID</th>
                  <th className="name">Policy Name</th>
                  <th className="email">No of section</th>
                  <th className="action">Edit</th>
                </tr>
              </thead>
              <tbody>
                {policies.map((policy) => (
                  <tr key={`policy_${policy.id}`}>
                    <td className="sno">{policy.id}</td>
                    <td className="name">{policy.name}</td>
                    <td className="email">{policy.sections.length}</td>
                    <td className="edit">
                      <button
                        type="button"
                        className="edit-btn"
                        onClick={() =>
                          navigate(`/policy-procedure/${policy.id}`)
                        }
                      >
                        <i
                          className="fa fa-pencil-square-o"
                          aria-hidden="true"
                        ></i>
                      </button>
                      <button
                        className="delete"
                        type="button"
                        onClick={() => deletePolicy(policy.id)}
                      >
                        <i className="fa fa-trash-o" aria-hidden="true"></i>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </section>

      <PolicyModal
        isOpen={privacyModal}
        toggle={() => setPrivacyModal(!privacyModal)}
        policies={defaultPolicies}
        onAdd={onAdd}
      />
      {/* Policies Table */}

      {spinner ? <Spinner /> : null}

      {/* Footer Start Here */}
      <Footer />
      {/* Footer End Here */}
    </>
  );
};

const mapStateToProps = (state) => ({
  policies: state.policies,
  authToken: state.auth.token,
});

const mapDispatchToProps = (dispatch) => ({
  getPolicies: async () => dispatch(await GetPoliciesAction()),
  addPolicy: async (policyId) => dispatch(await AddPolicyAction(policyId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Policies);
