import React, { useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import {
  ContactImage,
  CourseImg,
  ValueIcon1,
  ValueIcon2,
  ValueIcon3,
  ValueIcon4,
  ValueIcon5,
} from "../../constant/Index";
import "../../assets/css/lead-course.css";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import config from "../../config";
import { CONTACT_ENDPOINT } from "../../endpoints";
import MySpinner from "../../components/MySpinner";
import Swal from "sweetalert2";

const LeadCourse = () => {
  const [agree, setAgree] = useState(false);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [company, setCompany] = useState("");
  const [location, setLocation] = useState("");
  const [message, setMessage] = useState("Inquiry about IPC Lead Course");

  const [spinner, setSpinner] = useState(false);

  const onSubmit = async () => {
    if (!agree) {
      Swal.fire({
        icon: "error",
        title: "Consent Needed",
        text: "You need to agree in order to continue!",
      });
      return false;
    }
    try {
      setSpinner(true);
      await config.postApi(
        CONTACT_ENDPOINT,
        config.defaultHeaders,
        JSON.stringify({
          name,
          email,
          phone,
          company,
          location,
          message,
          type: "Inquiry About IPC Lead Course",
        })
      );
      setSpinner(false);
    } catch (e) {
      setSpinner(false);
      console.log(e);
    }
  };

  const options = {
    margin: 30,
    responsiveClass: true,
    nav: true,
    loop: true,
    dots: false,
    autoplay: true,
    navText: ["<", ">"],
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 2,
      },
      700: {
        items: 2,
      },
      991: {
        items: 3,
      },
    },
  };
  const coreValueData = [
    {
      id: 1,
      icon: ValueIcon1,
      name: "Antimicrobial Stewardship",
      description:
        "Your aged care facility is a reservoir for multi-resistant organisms. This module increases your knowledge and understanding of Antimicrobial Stewardship, use new skills to reduce antimicrobial resistance and improve infection prevention.",
    },
    {
      id: 2,
      icon: ValueIcon2,
      name: "Surveillance",
      description:
        "What is infection surveillance and why is it important? Learn the importance of infection surveillance and how to analyse the data. You can use this information to implement quality improvements in infection prevention and reduce healthcare acquired infections.",
    },
    {
      id: 3,
      icon: ValueIcon3,
      name: "Outbreaks",
      description:
        "You are ready to prevent, prepare, and manage outbreaks confidently. Outbreaks are the new norm in aged care and this module will assist you to successfully lead your team through an IP Leads greatest challenge.",
    },
    {
      id: 4,
      icon: ValueIcon4,
      name: "Preparing for Success",
      description:
        "Prepare for success with module 1. Your role and responsibilities are outlined clearly in this module and taking you through the steps needed to make an impact in the lives of your residents.",
    },
    {
      id: 5,
      icon: ValueIcon5,
      name: "Quality Improvement",
      description:
        "The practical steps to implement sustainable improvements. This module has the tools you need to make an impact as an IP Lead. Here you will develop an understanding of quality improvement how to use the multimodal approach for making positive changes that last.",
    },
  ];
  return (
    <>
      {/* Header Start Here */}
      <Header />
      {/* Header End Here */}

      {/* Course Feature Sec Start Here */}
      <section className="course_feature">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="img-box wow animate__animated animate__fadeInLeft">
                <figure>
                  <img
                    src={CourseImg}
                    alt="course-feature-img"
                    className="img-fluid"
                  />
                </figure>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="content-wrapper wow animate__animated animate__fadeInRight">
                <div className="heading-wrapper">
                  <h2 className="title">Course Features</h2>
                  <p>IP Lead Continuing Education Power Pack</p>
                </div>
                <div className="details-and-price">
                  <ul className="points">
                    <li>Positions IP Lead for success</li>
                    <li>100% online course</li>
                    <li>5 interactive modules</li>
                    <li>Certificate of completion</li>
                  </ul>
                  <div className="right-box">
                    <div className="price">
                      <h2>$595</h2>
                    </div>
                    <div className="button-group">
                      <Link to="/product-detail/17" className="primary-btn">
                        Sign Up Now!
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Course Feature Sec End Here */}

      {/* Core Values Sec Start Here */}
      <section className="core-value">
        <div className="container">
          <div className="core-value-slider">
            <OwlCarousel {...options}>
              {coreValueData?.map((data, index) => (
                <div
                  className="value-box wow animate__animated animate__flipInY"
                  key={index}
                >
                  <div className="icon-box">
                    <figure>
                      <img
                        src={data?.icon}
                        alt="value-icon"
                        className="img-fluid"
                      />
                    </figure>
                  </div>
                  <div className="content">
                    <h3 className="name">{data?.name}</h3>
                    <p>{data?.description}</p>
                  </div>
                </div>
              ))}
            </OwlCarousel>
          </div>
        </div>
      </section>
      {/* Core Values Sec End Here */}

      {/* Subscription Sec Start Here */}
      <section className="subscription_sec wow animate__animated animate__flipInY">
        <div className="container">
          <div className="subscribe-box wow animate__animated animate__backInDown animate__delay-1s">
            <div className="heading">
              <h2>BC Subscription</h2>
            </div>
            <p>
              You have more important things to do with your time. We provide
              all your IPC Policies and Procedures.
              <br />
              Subscribe to Bug Control
            </p>
            <div className="button-group mt-5">
              <Link to="/packages" className="primary-btn">
                Subscribe
              </Link>
            </div>
          </div>
        </div>
      </section>
      {/* Subscription Sec End Here */}

      {/* Contact Form Start Here */}
      <section className="contact_form">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-5">
              <div className="form-box wow animate__animated animate__fadeInLeft">
                <div className="title-wrapper">
                  <h3>
                    Find out more about our fast and effective IPC Lead Course
                    by filling in this form.
                  </h3>
                </div>
                <form action="">
                  <div className="form-group">
                    <label>Full Name</label>
                    <input
                      type="text"
                      className="form-control"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <label>Email Address</label>
                    <input
                      type="email"
                      className="form-control"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <label>Company</label>
                    <input
                      type="text"
                      className="form-control"
                      value={company}
                      onChange={(e) => setCompany(e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <label>Phone Number</label>
                    <input
                      type="tel"
                      className="form-control"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <label>Consent</label>
                    <div className="form-check">
                      <input
                        type="checkbox"
                        id="for-check"
                        className="form-control"
                        checked={agree}
                        onChange={(e) => setAgree(e.target.checked)}
                      />
                      <label htmlFor="for-check">
                        I agree to receive email communication from Infection
                        Control
                      </label>
                    </div>
                  </div>
                  <div className="button-group">
                    <button
                      className="primary-btn"
                      type="button"
                      onClick={onSubmit}
                      disabled={spinner}
                    >
                      {spinner ? <MySpinner /> : "Submit"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-lg-7">
              <div className="img-box wow animate__animated animate__fadeInRight animate__delay-1s">
                <figure>
                  <img
                    src={ContactImage}
                    alt="contact-image"
                    className="img-fluid"
                  />
                </figure>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Contact Form End Here */}

      {/* Footer Start Here */}
      <Footer />
      {/* Footer End Here */}
    </>
  );
};

export default LeadCourse;
