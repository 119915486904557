import { GET_LOCATION } from "./types";

export const GetLocationAction = () => async (dispatch, getState) => {
  let res = await (await fetch("https://api.ipify.org/?format=json")).json();
  if (res && res.ip) {
    res = await (
      await fetch("https://api.iplocation.net/?ip=" + res.ip)
    ).json();
    if (res && res.response_code == "200") {
      dispatch({ type: GET_LOCATION, payload: res });
    }
  }
};
