import {
  CLEAR_ADDRESSES,
  CLEAR_PAYMENT_METHODS,
  LOGIN,
  LOGOUT,
  UPDATE_PROFILE,
} from "./types";
import {
  LOGIN_ENDPOINT,
  REGISTER_ENDPOINT,
  UPDATE_PROFILE_ENDPOINT,
} from "../../endpoints";
import config from "../../config";

export const RegisterAction =
  (
    first_name,
    last_name,
    email,
    password,
    password_confirmation,
    phone,
    facility,
    login = false
  ) =>
  async (dispatch, getState) => {
    const headers = config.defaultHeaders;
    const data = JSON.stringify({
      first_name,
      last_name,
      email,
      password,
      password_confirmation,
      phone,
      facility,
      login,
    });
    const res = await config.postApi(REGISTER_ENDPOINT, headers, data);
    if (res) {
      if (login) {
        dispatch({ type: LOGIN, payload: res });
      }
      console.log(res);
    }
  };

export const LoginAction = (email, password) => async (dispatch, getState) => {
  const headers = config.defaultHeaders;
  const data = JSON.stringify({
    email,
    password,
  });
  const res = await config.postApi(LOGIN_ENDPOINT, headers, data, false);
  if (res) {
    dispatch({ type: LOGIN, payload: res });
    return res;
  }
};

export const LogoutAction = () => (dispatch, getState) => {
  dispatch({ type: CLEAR_PAYMENT_METHODS });
  dispatch({ type: CLEAR_ADDRESSES });
  dispatch({ type: LOGOUT });
};

export const UpdateProfileAction = (user) => async (dispatch, getState) => {
  const headers = config.bearerMultipartHeaders(getState().auth.token);
  const res = await config.postApi(UPDATE_PROFILE_ENDPOINT, headers, user);
  if (res) {
    dispatch({ type: UPDATE_PROFILE, payload: res });
  }
};
