import React from "react";
import { Link } from "react-router-dom";

const Banner = () => {
  return (
    <>
      {/* Banner Start Here */}
      <section className="banner_sec wow animate__animated animate__zoomIn">
        <div className="container">
          <div className="content-wrapper">
            <h1 className="title wow animate__animated animate__fadeInDown animate__delay-1s">
              {/* OUR INFECTION PREVENTION SOLUTIONS GIVE YOU BACK TIME AND SAVES YOU MONEY */}
              Our Unlimited Infection Plans Save You time and money
            </h1>
            <h4 className="wow animate__animated animate__fadeInDown animate__delay-2s">
              We have all the policies and procedures for infection prevention
              and control
            </h4>
            <div className="button-group wow animate__animated animate__fadeInDown animate__delay-3s">
              <Link to="/packages" className="primary-btn">
                Subscribe Now
              </Link>
            </div>
          </div>
        </div>
      </section>
      {/* Banner End Here */}

      {/* Tagline Sec Start Here */}
      <section className="tagline">
        <div className="container">
          <div className="content wow animate__animated animate__bounceIn">
            <h2 className="title">
              What if your infection prevention was organised, easily accessible
              and straightforward
            </h2>
            <h4>
              Our range of infection prevention and control products are easy to
              use, accurate and save you time.
            </h4>
          </div>
        </div>
      </section>
      {/* Tagline Sec End Here */}
    </>
  );
};

export default Banner;
