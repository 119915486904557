import React from 'react';
import Faqs from '../../components/Faqs/Faqs';
import { ContactBanner } from '../../constant/Index';
import Header from '../../components/Header/Header';
import InnerBanner from '../../components/InnerBanner/InnerBanner';
import Footer from '../../components/Footer/Footer';

const Faq_page = () => {
    return (
        <>
            {/* Header Start Here */}
            <Header />
            {/* Header End Here */}

            {/* InnerBanner Sec Start Here */}
            <InnerBanner
                PageName="FAQs"
                BannerImage={ContactBanner}
            />
            {/* InnerBanner Sec End Here */}

            {/* Faqs Sec Start Here */}
            <section className="faqs_sec" id="faqs_section">
                <div className="container">
                    <Faqs />
                </div>
            </section>
            {/* Faqs Sec End Here */}

            {/* Footer Start Here */}
            <Footer />
            {/* Footer End Here */}
        </>
    )
}

export default Faq_page