import React, { useEffect } from "react";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import Zoom from "react-medium-image-zoom";
import { useState } from "react";
import "../../assets/css/product-detail.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import Product from "../../components/Product/Product";
import config from "../../config";
import { PRODUCTS_ENDPOINT, PRODUCT_REVIEWS_ENDPOINT } from "../../endpoints";
import MySpinner from "../../components/MySpinner";
import { connect } from "react-redux";
import { ADD_TO_CART } from "../../redux/actions/types";
import Swal from "sweetalert2";
import moment from "moment";
import { Rating } from "react-simple-star-rating";
import MyInputFormGroup from "../../components/MyInputFormGroup";

const ProductDetail = ({ addToCart, auth }) => {
  const { id } = useParams();

  const [product, setProduct] = useState({});
  const [relatedProducts, setRelatedProducts] = useState([]);

  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  const navigateTo404 = () => {
    navigate("/404");
  };

  const getProduct = async () => {
    const res = await config.getApi(
      PRODUCTS_ENDPOINT + "/" + id,
      config.defaultHeaders,
      navigateTo404
    );
    setProduct(res.product);
    setRelatedProducts(res.related_products);
    setLoading(false);
  };

  useEffect(() => {
    getProduct().then();
  }, []);

  const [quantity, setQuantity] = useState(0);
  if (quantity < 1) {
    setQuantity(1);
  }

  const onAddToCart = (showAlert = true) => {
    addToCart({ product_id: id, product: product, quantity });
    if (showAlert) {
      Swal.fire({
        title: "Done",
        icon: "success",
        text: "Product Added to Cart!",
      });
    }
  };
  const [rating, setRating] = useState(0);
  const [review, setReview] = useState("");

  // Catch Rating value
  const handleRating = (rate) => {
    setRating(rate);
  };
  const onBuyNow = () => {
    onAddToCart(false);
    navigate("/product-cart");
  };

  const submitReview = async () => {
    try {
      const headers = config.bearerHeaders(auth.token);
      const res = await config.postApi(
        PRODUCT_REVIEWS_ENDPOINT(id),
        headers,
        JSON.stringify({ rating, review }),
        false
      );
      if (res) {
        setRating(0);
        setReview("");
        await getProduct();
      }
    } catch (e) {
      console.log(e);
    }
  };

  if (loading) {
    return (
      <>
        <Header />
        <div className="d-flex justify-content-center align-items-center">
          <MySpinner />
        </div>
        <Footer />
      </>
    );
  }

  return (
    <>
      {/* Header Start Here */}
      <Header />
      {/* Header End Here */}

      {/* Product Detail Sec Start Here */}
      <section className="product-detail">
        <div className="container">
          <div className="row">
            <div className="col-lg-5">
              <div className="product-slider-wrapper">
                <div className="main-slider-wrapper">
                  <img
                    src={config.returnImage(product.cover)}
                    className="img-fluid"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-7">
              <div className="product-detail-content-wrapper">
                <div className="product-breadcrumbs-wrapper">
                  <ul>
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>
                      <Link to="/shop">Shop</Link>
                    </li>
                    <li>
                      <Link className="active" to="#">
                        {product.name}
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="detail-wrapper">
                  <h3>{product.name}</h3>
                  {/* <h4>AMS Compendium Bundle</h4> */}
                  <div className="reviews-wrapper">
                    <ul>
                      <li>
                        <i
                          className={`fa fa-star${
                            product.rating >= 1 ? "" : "-o"
                          }`}
                        ></i>
                      </li>
                      <li>
                        <i
                          className={`fa fa-star${
                            product.rating >= 2 ? "" : "-o"
                          }`}
                        ></i>
                      </li>
                      <li>
                        <i
                          className={`fa fa-star${
                            product.rating >= 3 ? "" : "-o"
                          }`}
                        ></i>
                      </li>
                      <li>
                        <i
                          className={`fa fa-star${
                            product.rating >= 4 ? "" : "-o"
                          }`}
                        ></i>
                      </li>
                      <li>
                        <i
                          className={`fa fa-star${
                            product.rating == 5 ? "" : "-o"
                          }`}
                        ></i>
                      </li>
                      <li>
                        <span className="total-reviews">
                          {product.reviews.length} Reviews{" "}
                        </span>
                      </li>
                    </ul>
                  </div>
                  <div className="price-wrapper">
                    <h4>Price:</h4>
                    <h3>${product.price?.toFixed(2)}</h3>
                  </div>
                  <div className="description">
                    <p>{product.description}</p>
                  </div>
                  <div className="quantity-card-wrapper">
                    <div className="quantity-wrapper">
                      <h4>Quantity:</h4>
                      <div className="quantity-input-wrapper">
                        <button
                          onClick={() => setQuantity(quantity - 1)}
                          className="back"
                        >
                          <i className="fa fa-angle-left"></i>
                        </button>
                        <input
                          type="number"
                          value={quantity}
                          onChange={(e) => setQuantity(e.target.value)}
                        />
                        <button
                          onClick={() => setQuantity(quantity + 1)}
                          className="back"
                        >
                          <i className="fa fa-angle-right"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="btn-wrapper">
                    <button className="btn" type="button" onClick={onAddToCart}>
                      Add To Cart
                    </button>
                    <button className="btn" type="button" onClick={onBuyNow}>
                      Buy Now
                    </button>
                  </div>
                  <div className="other-deatils-wrapper">
                    <ul>
                      <li>
                        <span className="property">SKU:</span>
                        <span className="value">{product.sku}</span>
                      </li>
                      <li>
                        <span className="property">Category:</span>
                        <span className="value">{product.category?.name}</span>
                      </li>
                      <li>
                        <span className="property">Tags:</span>
                        <span className="value">{product.tags.join(", ")}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Product Detail Sec End Here */}

      {/* Product Description Sec Start Here */}
      <section className="products-tab-wrapper">
        <div className="container">
          <div className="row">
            <div className="col-lg-3">
              <div className="details-tab-wrapper">
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      id="Description-tab"
                      href="#Description"
                      data-toggle="tab"
                      role="tab"
                      aria-controls="Description"
                      aria-selected="true"
                    >
                      Description
                    </a>
                  </li>
                  <li className="nav-item">
                    <button
                      className="nav-link"
                      id="Reviews-tab"
                      data-toggle="tab"
                      data-target="#Reviews"
                      role="tab"
                      aria-controls="Reviews"
                      aria-selected="false"
                    >
                      Reviews <span>({product.reviews.length})</span>
                    </button>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-9">
              <div className="tab-content" id="myTabContent">
                <div
                  className="tab-pane fade show active"
                  id="Description"
                  role="tabpanel"
                  aria-labelledby="Description-tab"
                >
                  <div className="description-wrapper">
                    <div className="heading-warpper">
                      <h2>Description</h2>
                    </div>
                    <p>{product.description}</p>
                  </div>
                  <div className="contains_box">
                    <div className="heading-wrapper">
                      <h2>Contains</h2>
                    </div>
                    <p dangerouslySetInnerHTML={{ __html: product.contains }}>
                      {/* Increased antibiotic use in the aged care setting has
                      become a significant threat to our residents with the
                      increasing use of antibiotics in facilities and the
                      community. */}
                    </p>
                    {/* <ul className="points">
                      <li>Includes 10 videos</li>
                      <li>
                        Tools, Guides, Flowcharts, Posters and so much more
                      </li>
                      <li>Contains 383 Pieces</li>
                    </ul> */}
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="Reviews"
                  role="tabpanel"
                  aria-labelledby="Reviews-tab"
                >
                  <div className="reviews-wrapper">
                    {/* <div className="heading-wrapper">
                      <h5>Our Clients Reviews</h5>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et
                        dolore.Lorem ipsum dolor sit amet, consectetur
                        adipiscing elit, sed do eiusmod tempor incididunt ut
                        labore et dolore.Lorem ipsum dolor sit amet,Lorem ipsum
                        dolor sit amet
                      </p>
                    </div> */}
                    {product.reviews.map((review) => (
                      <div
                        className="single-review-wrapper"
                        key={`review_${review.id}`}
                      >
                        <div className="single-img-wrapper">
                          <figure>
                            <img
                              style={{
                                height: 110,
                                width: 110,
                                borderRadius: 100,
                              }}
                              src={config.returnAvatar(review.user)}
                              className="img-fluid"
                              alt=""
                            />
                          </figure>
                        </div>
                        <div className="single-reviews-content-wrapper">
                          <h5>{review.user.full_name}</h5>
                          <h6>
                            {moment(review.created_at).format("DD MMM YYYY")}
                          </h6>
                          <p>{review.review}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="add-review-wrapper">
                    <div className="heading-wrapper">
                      <h3>Add Review</h3>
                    </div>
                    <div className="review-form-wrapper">
                      {auth.token ? (
                        <>
                          <div className="form-group row">
                            <div className="col-lg-4">
                              <div className="rate-wrapper">
                                <label htmlFor="" className="mb-0">
                                  Your Rating
                                </label>
                                <Rating
                                  onClick={handleRating}
                                  size={20}
                                  initialValue={rating}
                                />
                              </div>
                            </div>
                          </div>
                          <MyInputFormGroup
                            label="Your Review"
                            placeholder="Your Review"
                            inputId="review"
                            value={review}
                            onChange={(e) => setReview(e.target.value)}
                            // type="textarea"
                            textarea
                          />
                          {/* <div className="form-group">
                            <textarea
                              name=""
                              placeholder="Your Review"
                              className="form-control"
                              id=""
                              cols="30"
                              rows="5"
                            ></textarea>
                          </div> */}
                          <div className="form-group row mt-4">
                            <div className="col-lg-10">
                              {/* <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  value=""
                                  id="flexCheckDefault"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="flexCheckDefault"
                                >
                                  By using this form you agree with the storage
                                  and handling of your data by this website.
                                </label>
                              </div> */}
                            </div>
                            <div className="col-lg-2">
                              <button
                                type="button"
                                onClick={submitReview}
                                className="primary-btn"
                              >
                                Submit
                              </button>
                            </div>
                          </div>
                        </>
                      ) : (
                        <span>
                          <Link to="/login" className="d-inline-block">
                            Login
                          </Link>{" "}
                          to add your review!
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Product Description Sec End Here */}

      {/* Related Product Sec Start Here */}
      <section className="related_sec">
        <div className="container">
          <div className="heading-wrapper pb-4">
            <h2>Related Products</h2>
          </div>
          <div className="row">
            <Product ProductData={relatedProducts} />
          </div>
        </div>
      </section>
      {/* Related Product Sec End Here */}

      {/* Footer Start Here */}
      <Footer />
      {/* Footer End Here */}
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  addToCart: (cartItem) => dispatch({ type: ADD_TO_CART, payload: cartItem }),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductDetail);
