import React from "react";
import { FormGroup, Label } from "reactstrap";
import Select from "react-select";

const MyMultiSelect = ({
  placeholder,
  value,
  label,
  options,
  onValueChange,
}) => {
  return (
    <FormGroup>
      <Label>{label}</Label>
      <Select
        isMulti
        placeholder={placeholder}
        options={options}
        value={value}
        onChange={(e) => onValueChange(e)}
      />
    </FormGroup>
  );
};

export default MyMultiSelect;
