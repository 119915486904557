import { GET_POLICIES, CLEAR_POLCIIES, ADD_POLICY } from "../actions/types";

const policies = (state = [], action) => {
  switch (action.type) {
    case GET_POLICIES:
      return action.payload;
    case ADD_POLICY:
      return [action.payload, ...state];
    case CLEAR_POLCIIES:
      return [];
    default:
      return state;
  }
};

export default policies;
