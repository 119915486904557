import React, { useState } from "react";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from "reactstrap";
import MyInputFormGroup from "./MyInputFormGroup";
import MyMultiSelect from "./MyMultiSelect";

const AssignCourseModal = ({
  isOpen,
  toggle,
  courses,
  enrollments,
  onEnroll,
}) => {
  const [spinner, setSpinner] = useState(false);
  const [courseId, setCourseId] = useState([]);

  return (
    <>
      <Modal
        className="policy-modal"
        isOpen={isOpen}
        toggle={toggle}
        centered={true}
      >
        <ModalHeader toggle={toggle}>Assign Course</ModalHeader>
        <ModalBody>
          <div className="row">
            <div className="col-md-12">
              <MyMultiSelect
                value={courseId}
                onValueChange={(e) => setCourseId(e)}
                placeholder={"Select Modules"}
                options={courses.map((course) => {
                  return {
                    label: course.title,
                    value: course.id,
                  };
                })}
              />
              {/* <MyInputFormGroup
                value={courseId}
                onChange={(e) => setCourseId(e.target.value)}
                placeholder="Last Name"
                inputId="Courseselect"
                type="select"
                options={[
                  { label: "Select Course", value: null },
                  ...courses.map((course) => {
                    return {
                      label: course.title,
                      value: course.id,
                    };
                  }),
                ]}
              /> */}
            </div>
            <div className="col-md-12">
              <div className="button-group">
                <button
                  className="primary-btn w-100"
                  type="button"
                  disabled={spinner}
                  onClick={async () => {
                    console.log(courseId);
                    setSpinner(true);
                    await onEnroll(courseId);
                    setCourseId([]);
                    setSpinner(false);
                    toggle();
                  }}
                >
                  {spinner ? <Spinner /> : "Submit"}
                </button>
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button type="button" className="primary-btn" onClick={toggle}>
            Close
          </button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default AssignCourseModal;
