import React from "react";
import { Link } from "react-router-dom";
import config from "../config";

const SubscriptionBox = ({
  paid = false,
  title,
  navigateTo = null,
  onClick = null,
  user_add_module = false,
  addUserModal = null,
}) => {
  if (paid) {
    return (
      <div className="subscription-box paid">
        <div className="inner-box">
          <div className="txt">
            <h4>{title}</h4>
          </div>
          {onClick && (
            <button type="button" onClick={onClick}>
              Enter
            </button>
          )}
          {navigateTo && (
            <div className="button-group">
              <Link
                to={navigateTo}
                className="btn btn-primary"
                style={{
                  backgroundColor: "#FFF",
                  borderColor: "#FFF",
                }}
              >
                Enter
              </Link>
            </div>
          )}
          {/* {user_add_module && (
            <span>
              "Add User" to this module using the{" "}
              <i class="fa fa-user-plus" aria-hidden="true"></i> button on the
              top right of your dash
            </span>
          )} */}
          {user_add_module && (
            <>
              <div className="button-group more-buttons">
                <button type="button" onClick={addUserModal}>
                  <i className="fa fa-user-plus"></i> Add User
                </button>
                <br />
                <Link type="button" to="/stats-dashboard">
                  Reports
                </Link>
              </div>
            </>
          )}
        </div>
      </div>
    );
  } else {
    return (
      <div className="subscription-box unpaid">
        <div className="inner-box">
          <div className="txt">
            <h4>{title}</h4>
          </div>
        </div>
        <div className="hover-box">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="28"
            height="28"
            fill="currentColor"
            className="bi bi-lock"
            viewBox="0 0 16 16"
          >
            <path d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2zm3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2zM5 8h6a1 1 0 0 1 1 1v5a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1z" />
          </svg>
          <div className="button-group">
            <Link to="/modules">Learn more</Link>
          </div>
        </div>
      </div>
    );
  }
};

export default SubscriptionBox;
